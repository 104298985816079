<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <h1 class="text-center">ยินดีต้อนรับสู่ระบบนัดหมาย</h1>
      <div v-if="hospital && hospital.logo">
        <div>
          <img :src="hospital.logo.small" /> 
        </div>
        <div>
          {{ hospital.name }}
        </div>
      </div>
      <div class="mt-4">
        <h2>เลือกกิจกรรมนัด</h2>
        <ul>
          <li v-for="activity in activities" :key="activity.id">
            {{ activity.name }}
          </li>
        </ul>
      </div>
      <div>
          <el-button 
            type="primary" size="large" class="next-btn" 
            @click="next()">เลือกกิจกรรมนัด &gt;</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState} from "vuex";

export default {
  components: {
 
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      hospital: state => state.requestAppointment.hospital,
      pets: state => state.owner.pets,
      activities: state => state.requestAppointment.activities,
    }),
  },
  methods: {
   
  },
  async mounted() {
    let query = { };
    query.hospital = 30;
    query.pet_type = 1;

    try {
      await this.$store.dispatch("owner/listPets");
      await this.$store.dispatch("requestAppointment/listActivities", {
        query
      })

    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
  }
}
</script>