<template>
  <div class="pet-InsurancesClaim">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกประกันสัตว์เลี้ยง</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="title">ส่งเอกสารเคลม</div>
        <div class="grid justify-items-center">
          <el-image
            style="width: 100px; height: 100px"
            :src="pet?.image?.medium"
            lazy
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="lead">{{ pet?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.type?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.breed?.name }}</div>
      </div>

      <div class="flex space-x-4">
        <div class="flex-none text-gray-400">บริษัทประกัน :</div>
        <div class="shrink">{{ insuranceClaim?.plan?.company?.name }}</div>
      </div>
      <div class="flex space-x-4">
        <div class="flex-none text-gray-400">แผนความคุ้มครอง : :</div>
        <div class="shrink">{{ insuranceClaim?.plan?.name }}</div>
      </div>
      <div class="flex space-x-4">
        <div class="flex-none text-gray-400">เลขกรมธรรม์ :</div>
        <div class="shrink">{{ insuranceClaim?.insurance_number }}</div>
      </div>
      <div class="flex space-x-4">
        <div class="flex-none text-gray-400">วันที่เริ่ม :</div>
        <div class="shrink">
          {{ displayDateFormat(insuranceClaim?.start_date) }}
        </div>
      </div>
      <div class="flex space-x-4">
        <div class="flex-none text-gray-400">วันที่สิ้นสุด :</div>
        <div class="shrink">
          {{ displayDateFormat(insuranceClaim?.end_date) }}
        </div>
      </div>
      <div class="flex space-x-4 my-2">
        <div class="flex-none text-gray-400">
          แนบเอกสาร <span class="text-red-500">*</span>
        </div>
      </div>
      <el-row>
        <el-col class="my-2">
          <el-upload
            action=""
            ref="upload"
            multiple
            :on-change="handleUploadReceipt"
            :auto-upload="false"
          >
            <div class="flex items-center space-x-4">
              <el-button type="primary" style="padding: 5px 5px">
                <img
                  src="@/assets/icons/camera.png"
                  alt="iTaam Logo"
                  width="25px"
              /></el-button>
              <span>ใบเสร็จ</span>
            </div>
          </el-upload>
        </el-col>
        <el-col class="my-2">
          <el-upload
            action=""
            ref="upload"
            multiple
            :on-change="handleUploadClaimDoc"
            :auto-upload="false"
          >
            <div class="flex items-center space-x-4">
              <el-button type="primary" style="padding: 5px 5px">
                <img
                  src="@/assets/icons/camera.png"
                  alt="iTaam Logo"
                  width="25px"
              /></el-button>
              <span>เอกสารเคลม</span>
            </div>
          </el-upload>
        </el-col>
        <el-col class="my-2">
          <el-upload
            action=""
            ref="upload"
            multiple
            :on-change="handleUploadBookBank"
            :auto-upload="false"
          >
            <div class="flex items-center space-x-4">
              <el-button type="primary" style="padding: 5px 5px">
                <img
                  src="@/assets/icons/camera.png"
                  alt="iTaam Logo"
                  width="25px"
              /></el-button>
              <span>สมุดบัญชี</span>
            </div>
          </el-upload>
        </el-col>
      </el-row>

      <el-collapse-transition>
        <el-form
          v-show="!tabPreview"
          :model="form"
          :rules="rules"
          ref="FormInsurances"
        >
          <el-form-item prop="contact_email">
            <span class="form-label"
              >Email เพื่อให้ทางประกันติดต่อกลับ
              <span class="text-red-500">*</span></span
            >
            <el-input v-model="form.contact_email"> </el-input>
          </el-form-item>

          <el-form-item>
            <span class="form-label">ข้อมูลเพิ่มเติม</span>
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
              v-model="form.detail"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-show="tabPreview" class="transition-box mt-4">
          <el-row class="my-2">
            <el-col class="text-gray-400">
              Email เพื่อให้ทางประกันติดต่อกลับ
            </el-col>
            <div class="shrink">{{ form?.contact_email }}</div>
          </el-row>
          <el-row class="my-2">
            <el-col class="text-gray-400"> ข้อมูลเพิ่มเติม </el-col>
            <div class="shrink">{{ form?.detail }}</div>
          </el-row>
        </div>
      </el-collapse-transition>

      <div class="type-select grid grid-cols-2 gap-2 mt-4">
        <div class="flex">
          <el-button
            class="grow"
            @click="tabPreview ? (tabPreview = false) : $router.go(-1)"
          >
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-show="!tabPreview">
          <el-button class="grow" type="primary" @click="validateForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ถัดไป</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-show="tabPreview">
          <el-button class="grow" type="primary" @click="submitForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ยืนยัน</span>
              </div>
            </div>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar";
import { get } from "vuex-pathify";
export default {
  components: {
    Subnavbar,
  },
  data() {
    return {
      rules: {
        contact_email: [
          {
            required: true,
            message: "Please input email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
      },
      form: {
        insurance: null,
        contact_email: null,
        contact_phone: null,
        detail: null,
      },
      attachments: [], // RECEIPT = 0, CLAIM_DOCUMENT = 1, BOOKBANK = 2
      tabPreview: false,
    };
  },
  computed: {
    pet: get("owner/pet"),
    insuranceClaim: get("insurance/insuranceClaimForm"),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.tabPreview = false;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const { results } = await this.$store.dispatch(
        "healthRecord/getListFoodCategory"
      );
      this.foodsList = results;
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    validateForm() {
      this.$refs.FormInsurances.validate((valid) => {
        if (!valid) return false;

        this.tabPreview = true;
      });
    },
    async submitForm() {
      let formData = new FormData();

      formData.append("insurance", this.insuranceClaim?.id);
      formData.append("contact_email", this.form.contact_email);
      formData.append("detail", this.form.detail);

      this.attachments.forEach((it, index) => {
        formData.append(`attachments[${index}]type`, it.type);
        formData.append(
          `attachments[${index}]attachment`,
          it.attachment?.raw,
          it.attachment?.name
        );
      });

      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("pet/saveInsuranceClaims", {
        data: formData,
        params: { id: this.pet.id },
      });
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      this.$router.push({
        name: "petProfile",
        params: { id: this.pet.id },
        query: { tab: "insurance" },
      });
    },
    showProfile() {
      if (this.pet?.owners?.length) {
        return this.pet?.owners[0]?.profile?.firstname;
      } else {
        return "-";
      }
    },
    handleUploadReceipt(file) {
      this.attachments.push({
        type: 0,
        attachment: file,
      });
    },
    handleUploadClaimDoc(file) {
      this.attachments.push({
        type: 1,
        attachment: file,
      });
    },
    handleUploadBookBank(file) {
      this.attachments.push({
        type: 2,
        attachment: file,
      });
    },
  },
};
</script>

<style lang="scss" scrop>
.pet-InsurancesClaim {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}

.form-block {
  &.standalone {
    @extend %block-container;
  }
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .el-button {
    max-height: 40px;
    padding: 12px 5px;
  }

  .type-select {
    .el-button {
      width: 48%;
    }
  }
  .el-checkbox {
    white-space: pre-wrap;
    display: flex;
  }

  .btn-remove {
    background: none;
    padding: 8px;
    border: none;
    // width: 30px;
    height: 100%;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .el-input:not(first-child) {
    margin-top: 4px;
  }
}
</style>