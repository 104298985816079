<template>
  <div class="insurance-item">
    <div class="header-title">
      เลขที่กรมธรรม์ {{ item.insurance_number || "กำลังรอเลขที่กรมธรรม์" }}
    </div>
    <el-card>
      <div class="flex">
        <div class="grow">
          <el-button
            type="primary"
            class="w-full"
            plain
            @click="setInsuranceClaim"
            >ส่งเอกสารเคลม</el-button
          >
        </div>
      </div>
      <div class="contain">
        <div class="font-black font-bold font-medium mt-10">
          แผนความคุ้มครอง {{ item.plan.name }}
        </div>
        <div class="font-black font-bold font-medium">
          วันที่เริ่ม {{ displayDateFormat(item.start_date) }}
        </div>
        <div class="font-black font-bold font-medium">
          วันสิ้นสุด {{ displayDateFormat(item.end_date) }}
        </div>
        <div class="font-gray font-small mt-14">
          บริษัท : {{ item.plan.company.name }}
        </div>
        <hr class="mt-14" style="border: solid 1px #979797" />
        <div class="font-black font-bold font-large">
          ความคุ้มครอง {{ item.plan.name }}
        </div>

        <div class="mt-10">
          <div
            class="mb-10"
            v-for="(rule, index) in item.plan.items"
            :key="index"
          >
            <div class="font-gray font-small">{{ rule.name }}</div>
            <div class="font-gray font-small">{{ rule.rule }}</div>
          </div>
        </div>

        <div v-if="item.insurance_document">
          <div class="font-black font-bold font-large mt-10">กรมธรรม์</div>
          <el-link
            class="pdf-container"
            :href="item.insurance_document"
            :underline="false"
            target="_blank"
            rel="norefferer"
          >
            PDF
          </el-link>
        </div>

        <div v-if="item.insurance_claim_document" class="mb-10">
          <div class="font-black font-bold font-large mt-10">เอกสารเคลม</div>
          <el-link
            class="pdf-container"
            :href="item.insurance_claim_document"
            :underline="false"
            target="_blank"
            rel="norefferer"
          >
            PDF
          </el-link>
        </div>
      </div>
    </el-card>

    <el-card
      class="mt-2"
      v-show="item?.claims?.length"
      v-for="(itClaim, index) in item?.claims"
      :key="index"
    >
      <div class="contain">
        <div class="font-black font-bold" style="font-size: 16px">
          วันที่ยื่นเคลม {{ displayDateFormat(itClaim?.date) }}
        </div>
        <el-divider></el-divider>

        <div class="grid grid-cols-3 gap-4">
          <div class="flex flex-col justify-items-center items-center">
            <div class="font-black font-bold font-large mt-10">เอกสารเคลม</div>
            <el-link
              class="pdf-container-small my-1"
              :href="itFile?.attachment"
              v-for="(itFile, inF) in filterFileByType(itClaim.attachments, 0)"
              :key="inF"
              :underline="false"
              target="_blank"
              rel="norefferer"
            >
              PDF
            </el-link>
          </div>
          <div class="flex flex-col justify-items-center items-center">
            <div class="font-black font-bold font-large mt-10">ใบเสร็จ</div>

            <el-link
              class="pdf-container-small my-1"
              :href="itFile?.attachment"
              v-for="(itFile, inF) in filterFileByType(itClaim.attachments, 1)"
              :key="inF"
              :underline="false"
              target="_blank"
              rel="norefferer"
            >
              PDF
            </el-link>
          </div>
          <div class="flex flex-col justify-items-center items-center">
            <div class="font-black font-bold font-large mt-10">สมุดบัญชี</div>
            <el-link
              class="pdf-container-small my-1"
              :href="itFile?.attachment"
              v-for="(itFile, inF) in filterFileByType(itClaim.attachments, 2)"
              :key="inF"
              :underline="false"
              target="_blank"
              rel="norefferer"
            >
              PDF
            </el-link>
          </div>
        </div>

        <el-row class="mt-2">
          <el-col class="text-sm text-gray-400"
            >Email เพื่อให้ทางประกันติดต่อกลับ</el-col
          >
          <el-col>{{ itClaim?.contact_email }}</el-col>
          <el-col class="text-sm text-gray-400">ข้อมูลเพิ่มเติม</el-col>
          <el-col>{{ itClaim?.detail }}</el-col>
        </el-row>
        <el-divider class="my-0 py-0"></el-divider>
        <p class="text-gray-400">ผู้บันทึก : {{ itClaim?.created_owner }}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["item"],
  computed: {},
  methods: {
    setInsuranceClaim() {
      this.$store.commit("insurance/SET_INSURANCE_CLAIM_FORM", this.item);
      this.$router.push({ name: "petInsurancesClaim" });
    },
    filterFileByType(item, type) {
      if (!item?.length) return [];

      return item?.filter((it) => it.type === type);
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-item {
  .contain {
    padding: 14px 18px;
  }

  .pdf-container {
    width: 91px;
    height: 87px;
    border-radius: 5px;
    background-color: #02b0e1;
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }
  .pdf-container-small {
    width: 57px;
    height: 57px;
    border-radius: 5px;
    background-color: #02b0e1;
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }

  .header-title {
    color: #000;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 20px;
  }

  .font-small {
    font-size: 12px;
  }

  .font-medium {
    font-size: 14px;
  }

  .font-large {
    font-size: 18px;
  }

  .font-bold {
    font-weight: bold;
  }

  .font-black {
    color: #030202;
  }

  .font-gray {
    color: #7f8fa4;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-14 {
    margin-top: 14px;
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
}
</style>