import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    all: [],
    selected: null,
    profile: null,
    searchResults: null,
    hospitalConnection: null,
    createForm: {},
    pet: null,
    pets: null,
    timeline: [],
  },
  getters: {
    profile: state => state.profile,
  },
  asyncActions: [
    {
      name: 'getOwnerTimeline',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/timeline/`,
      setState: 'timeline'
    },
    {
      name: 'getOwnerAppointmentTimeline',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/timeline/appointment/`,
      setState: 'timeline'
    },
    {
      name: 'getPetTimeline',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerPet}${id}/timeline/`,
      setState: 'timeline'
    },
    {
      name: 'getExpenseYearly',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/incomes/years/`,
    },
    {
      name: 'getExpenseHistory',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/incomes/`,
    },
    {
      name: 'getExpenseOverview',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/incomes/overview/`,
    },
    {
      name: 'getSegmentExpenseList',
      method: 'get',
      url: `${endpoints.owner}expense/`,
    },
    {
      name: 'getSegmentExpenseOverview',
      method: 'get',
      url: `${endpoints.owner}expense/overview/`,
    },
    {
      name: 'listOwnerProfile',
      method: 'get',
      url: endpoints.ownerProfiles,
      setState: 'all'
    },
    {
      name: 'getOwnerProfileById',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/`,
      setState: 'selected'
    },
    {
      name: 'existingSearch',
      method: 'get',
      url: endpoints.ownerExistingSearch,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_SEARCH_RESULTS', result);
        }
      }
    },
    {
      name: 'systemSearch',
      method: 'get',
      url: endpoints.ownerSystemSearch,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_SEARCH_RESULTS', result);
        }
      }
    },
    {
      name: 'ownerRegister',
      method: 'post',
      url: endpoints.ownerRegister,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_PROFILE', result);
        }
      }
    },
    {
      name: 'ownerOTPVerify',
      method: 'post',
      url: endpoints.ownerOTPVerify,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: 'getOwnerProfile',
      method: 'get',
      url: endpoints.ownerProfileMe,
      setState: 'profile'
      // onSuccess: (result, payload, { commit }) => {
      //   if (result) {
      //     commit('SET_PROFILE', result);
      //   }
      // }
    },
    {
      name: 'updateProfile',
      method: 'patch',
      url: endpoints.ownerProfileMe,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_PROFILE', result);
        }
      }
    },
    {
      name: 'unlinkProfile',
      method: 'post',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/unlink/`,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: 'getOwnerHospitalConnection',
      method: 'get',
      url: ({ secret_code }) => `${endpoints.ownerHospitalConnection}${secret_code}`,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_HOSPITAL_CONNECTION', result);
        }
      }
    },
    {
      name: 'ownerForgetPassword',
      method: 'post',
      url: endpoints.ownerForgetPassword,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_PROFILE', result);
        }
      }
    },
    {
      name: 'ownerForgetPasswordOTP',
      method: 'post',
      url: endpoints.ownerForgetPasswordOTP,
      setState: 'profile',
    },
    {
      name: 'ownerUpdatePassword',
      method: 'put',
      url: endpoints.ownerForgetPassword,
      setState: 'profile',
    },
    {
      name: 'createProfile',
      method: 'post',
      url: endpoints.ownerProfileCreate
    },
    {
      name: 'updateProfileById',
      method: 'patch',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/`
    },
    {
      name: 'listPetsByOwnerId',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/pets/`
    },
    {
      name: 'createPet',
      method: 'post',
      url: endpoints.ownerPet,
      setState: 'pet',
    },
    {
      name: 'updatePet',
      method: 'patch',
      url: ({ id }) => `${endpoints.ownerPet}${id}/`,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_PET', result);
        }
      }
    },
    {
      name: 'deletePetFromOwner',
      method: 'delete',
      url: ({ id, petId }) => `${endpoints.ownerProfiles}${id}/pets/${petId}/`
    },
    {
      name: 'listPets',
      method: 'get',
      url: endpoints.ownerPet,
      setState: 'pets',
    },
    {
      name: 'getPet',
      method: 'get',
      url: ({ id }) => `${endpoints.ownerPet}${id}/`,
      setState: 'pet',
    },
    {
      name: 'deletePet',
      method: 'delete',
      url: ({ id }) => `${endpoints.ownerPet}${id}/`,
    },
    {
      name: 'addPetToHospital',
      method: 'post',
      url: endpoints.ownerMeHospital,
    },
    {
      name: 'setLine',
      method: 'post',
      url: endpoints.ownerSocialLine,
    },
    {
      name: 'setMessenger',
      method: 'post',
      url: endpoints.ownerSocialMessenger,
    },
    {
      name: 'invite',
      method: 'post',
      url: endpoints.ownerInvite,
    },
    {
      name: 'resendOTP',
      method: 'post',
      url: endpoints.ownerResendOTP
    },
    {
      name: 'createPasswordOTP',
      method: 'post',
      url: endpoints.ownerCreatePassword
    },
    {
      name: 'createPassword',
      method: 'put',
      url: endpoints.ownerCreatePassword
    },
    {
      name: "listMessages",
      method: "get",
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/message/`
    },
    {
      name: "ownerTimelineMessages",
      method: "get",
      url: ({ id }) => `${endpoints.ownerProfiles}${id}/timeline/message/`
    },
    {
      name: "getAppoinmentTimeline",
      method: "get",
      url: `${endpoints.owner}me/timeline/appointment/`
    },
    {
      name: "listTimelineMessages",
      method: "get",
      url: () => `${endpoints.owner}me/timeline/message/`
    },
    {
      name: "listPetTimelineMessages",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/timeline/message/`
    },
    {
      name: "listPetTimelineAppointments",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/timeline/appointment/`
    },
    {
      name: "listPetTimelineAppointmentsActive",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/timeline/appointment/active/`
    },
    {
      name: "listPetTimelineHealthBooks",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/timeline/healthbook/`
    },
    {
      name: "listPetTimelineFinalDiagnosis",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/timeline/healthbook/finaldiagnosis/`
    },
    {
      name: "listPetTimelineBlood",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/timeline/blood/`
    },
    {
      name: "listPetTimelineInsurance",
      method: "get",
      url: ({ petId }) => `${endpoints.owner}me/pets/${petId}/insurances/`
    },


  ],
  actions: {
    logout({ commit }) {
      commit('SET_PROFILE', null);
    }
  }
};

export default vuexHelper.createStoreModule(config);
