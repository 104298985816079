import { coreAPI } from "@/services/apis";
import { POS_DETAIL, POS, POS_OVERVIEW } from "@/services/endpoints";

const namespaced = true;

const state = () => ({
  list: [],
  overview: {},
  id: undefined,
  receiptNumber: undefined,
  receiptId: undefined,
  customer: undefined,
  status: undefined,
  form: undefined,
  summary: undefined,
  paymentMethods: undefined,
  paymentSummary: undefined,
  paymentEvidences: undefined,
  isVoid: false,
  createdBy: undefined,
});

const getters = {};

const mutations = {
  SET_LIST: (state, value) => {
    state.list = value;
  },

  SET_OVERVIEW: (state, value) => {
    state.overview = value;
  },

  SET_ID: (state, value) => {
    state.id = value;
  },

  SET_RECEIPT_ID: (state, value) => {
    state.receiptId = value;
  },

  SET_RECEIPT_NUMBER: (state, value) => {
    state.receiptNumber = value;
  },

  SET_CUSTOMER: (state, value) => {
    state.customer = value;
  },

  SET_STATUS: (state, value) => {
    state.status = value;
  },

  SET_FORM: (state, value) => {
    state.form = value;
  },

  SET_SUMMARY: (state, value) => {
    state.summary = value;
  },

  SET_PAYMENT_METHODS: (state, value) => {
    state.paymentMethods = value;
  },

  SET_PAYMENT_SUMMARY: (state, value) => {
    state.paymentSummary = value;
  },

  SET_PAYMENT_EVIDENCES: (state, value) => {
    state.paymentEvidences = value;
  },

  SET_IS_VOID: (state, value) => {
    state.isVoid = value;
  },

  SET_CREATED_BY: (state, value) => {
    state.createdBy = value;
  },
};

const actions = {
  async list({ commit }, params) {
    const {
      data: { result },
    } = await coreAPI.get(POS, params);

    commit("SET_LIST", result.results);
    return result;
  },

  async listPaid({ commit }, params) {
    const {
      data: { result },
    } = await coreAPI.get(POS, params);

    commit("SET_LIST", result.results);
    return result;
  },

  async overview({ commit }, params) {
    const {
      data: { result },
    } = await coreAPI.get(POS_OVERVIEW, params);

    commit("SET_OVERVIEW", result);
    return result;
  },

  async fetch({ commit }, id) {
    const getToday = () => {
      const date = new Date();

      date.setSeconds(0, 0);

      return date;
    };

    const {
      data: { result },
    } = await coreAPI.get(POS_DETAIL.replace(":id", id));

    const posId = result.pos.id;

    const receiptId = result.pos.receipt ? result.pos.receipt.id : undefined;

    const receiptNumber = result.pos.receipt
      ? result.pos.receipt.receipt_number
      : undefined;

    const status = result.status;

    let customer;

    if (result.owner) {
      customer = {
        id: result.owner.id,
        name: `${result.owner.profile.firstname} ${result.owner.profile.lastname}`.trim(),
        tel: result.owner.phone,
        email: result.owner.profile.email,
        image: result.owner.profile.image.medium,
      };
    }

    const form = {
      date: result.datetime ? new Date(result.datetime) : getToday(),
      finishedDate: result.finished ? new Date(result.finished) : undefined,
      items: result.pos.posactivity_set.map((item) => ({
        id: item.activity.id,
        name: item.activity.name,
        products: item.posactivityproduct_set.map((item) => ({
          id: item.product.id,
          name: item.product.trade_name,
          amount: item.amount,
          unit: item.unit ? item.unit.name : "",
        })),
        value: +item.unit_price,
        getDescription() {
          return this.products.length > 0 ? this.products[0].name : "";
        },
        isTx: item.is_tx,
        isRx: item.posactivityproduct_set.length > 0 ? item.is_rx : undefined,
        lotNo: item.posactivityproduct_set.length > 0 ? item.lot_no : undefined,
        amount: item.amount,
        discount: +item.discount,
        hasVat: item.vat_percentage != null,
        vatPercentage: item.vat_percentage,
      })),
      hasDiscarded: !!result.discardedstandalonepos,
    };

    const summary = {
      beforeDiscount: +result.pos.grand_total_price.before_discount,
      discount: +result.pos.grand_total_price.discount,
      afterDiscount: +result.pos.grand_total_price.after_discount,
      taxless: +result.pos.grand_total_price_no_vat_only,
      tax: +result.pos.grand_total_price_exclude_vat,
      vat: +result.pos.total_vat,
    };

    let paymentMethods, paymentSummary, paymentEvidences, isVoid, createdBy;

    if (result.pos.receipt) {
      paymentMethods = result.pos.receipt.payments.map((item) => ({
        name:
          item.payment_method && item.payment_method.payment_method_type
            ? item.payment_method.payment_method_type.name || ""
            : "",
        bank:
          item.payment_method && item.payment_method.bank
            ? item.payment_method.bank.name || ""
            : "",
        accountNo: item.payment_method
          ? item.payment_method.bank_account_id || ""
          : "",
        amount: +item.amount,
        feePercentage: +item.fee_percentage,
        ref: item.ref,
        hasFee: item.payment_method && item.payment_method.has_fee,
      }));

      paymentSummary = {
        received: +result.pos.receipt.total_paid,
        afterDiscount: +result.pos.receipt.amount,
        fee: +result.pos.receipt.fee,
        paid: +result.pos.receipt.amount + +result.pos.receipt.fee,
        change: +result.pos.receipt.total_change,
      };

      paymentEvidences = result.pos.receipt.attachments.map(
        (item) => item.attachment
      );

      isVoid = !!result.pos.receipt.voided;
      createdBy = result.pos.receipt.payee;
    }

    commit("SET_ID", posId);
    commit("SET_RECEIPT_ID", receiptId);
    commit("SET_RECEIPT_NUMBER", receiptNumber);
    commit("SET_STATUS", status);
    commit("SET_CUSTOMER", customer);
    commit("SET_FORM", form);
    commit("SET_SUMMARY", summary);
    commit("SET_PAYMENT_METHODS", paymentMethods);
    commit("SET_PAYMENT_SUMMARY", paymentSummary);
    commit("SET_PAYMENT_EVIDENCES", paymentEvidences);
    commit("SET_IS_VOID", isVoid);
    commit("SET_CREATED_BY", createdBy);
  },

  reset: ({ commit }) => {
    commit("SET_ID", undefined);
    commit("SET_RECEIPT_ID", undefined);
    commit("SET_RECEIPT_NUMBER", undefined);
    commit("SET_STATUS", undefined);
    commit("SET_CUSTOMER", undefined);
    commit("SET_FORM", undefined);
    commit("SET_SUMMARY", undefined);
    commit("SET_PAYMENT_METHODS", undefined);
    commit("SET_PAYMENT_SUMMARY", undefined);
    commit("SET_PAYMENT_EVIDENCES", undefined);
    commit("SET_IS_VOID", undefined);
    commit("SET_CREATED_BY", undefined);
  },

  resetPartially: async ({ commit }) => {
    commit("SET_ID", undefined);
    commit("SET_RECEIPT_ID", undefined);
    commit("SET_RECEIPT_NUMBER", undefined);
    commit("SET_STATUS", undefined);
    commit("SET_SUMMARY", undefined);
    commit("SET_PAYMENT_METHODS", undefined);
    commit("SET_PAYMENT_SUMMARY", undefined);
    commit("SET_PAYMENT_EVIDENCES", undefined);
    commit("SET_IS_VOID", undefined);
    commit("SET_CREATED_BY", undefined);
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
