import RequestAppointmentPage from '@/pages/requestAppointment/RequestAppointmentPage.vue'
import RequestAppointmentPetsPage from '@/pages/requestAppointment/RequestAppointmentPetsPage.vue'
import RequestAppointmentActivitiesPage from '@/pages/requestAppointment/RequestAppointmentActivitiesPage.vue'

const routes = [
  {
    path: '/request-appointment',
    name: 'request-appointment',
    component: RequestAppointmentPage,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: '/request-appointment/:id',
    name: 'request-appointment-pets',
    component: RequestAppointmentPetsPage,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    },
  },
  {
    path: '/request-appointment/:hospital_id/pets/:pet_id',
    name: 'request-appointment-activities',
    component: RequestAppointmentActivitiesPage,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    },
  }
]

export default routes