import Vue from 'vue'
import ElementUI from 'element-ui'
import VeeValidate from 'vee-validate'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import './styles/index.css'
import locale from 'element-ui/lib/locale/lang/th'
import './scss/element-variables.scss'
import 'element-ui/lib/theme-chalk/display.css';

import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from './filters'


import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false
// eslint-disable-next-line no-undef
window.$ = window.jQuery = require('jquery')

Vue.use(ElementUI, { locale })
Vue.use(VeeValidate, { fieldsBagName: 'formFields' })
Vue.use(VueReCaptcha, { 
	siteKey: process.env.VUE_APP_GOOGEL_RECAPTCHA_SITE_KEY,
	loaderOptions: {
		autoHideBadge: true
	}
})

Vue.component(VueQrcode.name, VueQrcode)
Vue.component('v-select', vSelect)

// filters
for (let name in filters) {
	Vue.filter(name, filters[name])
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
