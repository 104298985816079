<template>
  <div class="pet-health">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกสุขภาพ</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="title">ยินดีต้อนรับสู่ระบบบันทึกสุขภาพ</div>
      </div>

      <div class="flex justify-between">
        <div>เลือกสัตว์เลี้ยง <span class="text-red-500">*</span></div>
        <div class="text-gray-400">เลือกได้ครั้งละ 1 ตัว</div>
      </div>

      <el-row class="mt-4">
        <el-col :span="24">
          <el-table
            :data="items"
            style="width: 100%"
            :header-cell-style="{ 'background-color': '#f5f8fa' }"
          >
            <el-table-column prop="name" label="ชื่อสัตว์" width="170">
              <template slot-scope="scope">
                <div class="flex items-center">
                  <img
                    class="pet-profile"
                    v-if="scope.row?.image"
                    :src="scope.row?.image?.small"
                  />
                  <img
                    class="pet-profile"
                    v-else
                    src="https://d9tsiz2rz5t80.cloudfront.net/media/__sized__/__placeholder__/owner_default_logo-thumbnail-200x200.png"
                  />
                  <span class="pet-name" style="margin-left: 10px">{{
                    scope.row?.name
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="Type" label="ชนิด" width="70">
              <template slot-scope="scope">
                <div class="flex items-center">
                  <img
                    class="pet-type"
                    v-if="scope.row?.type?.key == 'DOG'"
                    src="@/assets/icons/icon-dog.png"
                  />
                  <img
                    class="pet-type"
                    v-if="scope.row?.type?.key == 'CAT'"
                    src="@/assets/icons/icon-cat.png"
                  />
                  <span
                    v-if="
                      scope.row?.type?.key != 'DOG' &&
                      scope.row?.type?.key != 'CAT'
                    "
                    >{{ scope.row?.type?.name.split("/")[0] }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="action">
              <template slot-scope="scope">
                <div class="flex flex-row-reverse ...">
                  <el-button @click="handleSelect(scope.row)">เลือก</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <el-row type="flex" justify="center" class="mt-4">
        <el-col :span="10">
          <div class="flex">
            <el-button
              type="primary"
              class="grow"
              plain
              @click="$router.push({ name: 'petGeneralInfo' })"
              ><i class="el-icon-circle-plus"></i> เพิ่มสัตว์เลี้ยง
            </el-button>
          </div>
        </el-col>
      </el-row>

      <div class="type-select grid grid-cols-2 gap-2 mt-2">
        <div class="flex">
          <el-button
            class="grow"
            @click="$router.push({ name: 'petOwnerHome' })"
          >
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex">
          <el-button class="grow" type="primary">
            <!-- @click="$router.push({ name: 'petHealth' })" -->
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ถัดไป</span>
              </div>
            </div>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar";
import { get } from "vuex-pathify";
export default {
  components: {
    Subnavbar,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    pets: get("owner/pets"),
  },
  watch: {
    pets(val) {
      if (!val) return [];

      this.items = val.filter((it) => it.health_record_enabled);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("owner/listPets");
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    handleSelect(item) {
      this.$store.commit("owner/SET_PET", item);
      this.$router.push({
        name: "petHealth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pet-health {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}
.form-block {
  &.standalone {
    @extend %block-container;
    padding: 0px 20px 20px 20px;
  }
}

.el-divider--horizontal {
  margin: 10px 0;
}
thead tr {
  background-color: #f5f8fa;
}
.pet-profile {
  width: 50px;
}
.pet-type {
  width: 30px;
}
.gender {
  width: 15px;
}
</style>