import Vue from "vue";
import Vuex from "vuex";

import pathify from "./pathify";

Vue.use(Vuex);

// eslint-disable-next-line no-undef 
const debug = process.env.NODE_ENV !== "production";

if (debug) {
  pathify.debug();
}

// import modules
let modules = {};
// eslint-disable-next-line no-undef 
const requireModule = require.context("./modules", false, /\.js$/);
requireModule.keys().forEach(fileName => {
  const moduleConfig = requireModule(fileName);
  const moduleName = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
  modules[moduleName] = moduleConfig.default || moduleConfig;
});

export default new Vuex.Store({
  modules,
  strict: debug,
  plugins: [pathify.plugin]
});
