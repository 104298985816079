import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
  },
  asyncActions: [
    {
      name: 'updateAddress',
      method: 'patch',
      url: ({ id }) => `${endpoints.address}${id}/`
    },
    {
      name: 'createAddress',
      method: 'post',
      url: endpoints.address
    }
  ]
};

export default vuexHelper.createStoreModule(config);
