<template>
  <div></div>
</template>

<script>

export default {
  async created () {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    const social = this.$route.params.social;
    try {
      if (social == 'line') {
        const code = this.$route.query.code;
        await this.$store.dispatch('owner/setLine', {
          data: {
            code: code,
          }
        });
        this.$notify({
          message: `เชื่อมต่อ Line สำเร็จ`,
          type: "success"
        });
      } else if (social == 'messenger') {
        const account_linking_token = this.$route.query.account_linking_token;
        const redirect_uri = this.$route.query.redirect_uri;
        await this.$store.dispatch('owner/setMessenger', {
          data: {
            messenger_token: account_linking_token,
          }
        });
        window.location = `${redirect_uri}&authorization_code=${account_linking_token}`;
      }
    } catch(error) {
      this.$notify({
        message: `เชื่อมต่อไม่สำเร็จ`,
        type: "error"
      })
    }
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
    this.$router.push({ name: 'petOwnerHome' });
  }
}
</script>
