<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <h1 class="text-center">ยินดีต้อนรับสู่ระบบนัดหมาย</h1>
      <div class="mt-4">
        <label>เลือกสถานพยาบาล <span class="text-red-700">*</span></label>
        <el-input
            placeholder="ค้นหาสถานพยาบาล"
            v-model="searchValue"
            class="search-input"
            clearable
            @keyup.enter.native="search"
            @clear="clearSearch"
          >
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
      </div>
      <div class="h-80 overflow-y-scroll">
            <ul class="w-full">
              <li v-for="hospital in hospitals.results" :key="hospital.id" class="border-b" 
                :class="{ 'bg-sky-500': hospital.id === (selectedHospital && selectedHospital.id)}">
                <div class="inline-block w-1/4" v-if="hospital.logo">
                  <img :src="hospital.logo.small" /> 
                </div>
                <div class="inline-block w-2/4">
                  {{ hospital.name }}
                </div>
                <div class="inline-block w-1/4">
                  <el-button @click="select(hospital)">เลือก</el-button>
                </div>
              </li>
            </ul>
      </div>
      <div>
          <el-button 
            type="primary" size="large" class="next-btn" 
            @click="next()">เลือกวันเวลานัด &gt;</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import ContentBox from '@/components/container/ContentBox.vue';

export default {
  components: {
    // ContentBox
  },
  data() {
    return {
      searchValue: '',
      selectedHospital: null
    }
  },
  computed: {
    ...mapState({
      hospitals: state => state.requestAppointment.hospitals || []
    }),
  },
  methods: {
    async search() {
      try {
        await this.$store.dispatch("hospital/hospitalSearch", { });
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    clearSearch() {
      this.searchValue = ''
    },
    select(hospital) {
      this.selectedHospital = hospital;
    },
    next() {
      this.$router.push({ name: 'request-appointment-pets',  params: { id: this.selectedHospital.id } })
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("requestAppointment/listHospitals");
    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
  }
}
</script>