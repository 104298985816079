<template>
  <el-dialog
    :visible.sync="dialogVisible"
    custom-class="el-dialog-custom"
    center
  >
    <span>
      <el-image :key="imgsrc" :src="imgsrc" lazy class="show-image"></el-image
    ></span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      imgsrc: "",
    };
  },
  methods: {
    show(src) {
      this.imgsrc = src;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" >
.el-dialog-custom {
  width: fit-content;
}
// @media screen and (max-width: 600px) {
//   .el-dialog-custom {
//     width: 100% !important;
//   }
// }
</style>