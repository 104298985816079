<template>
  <div class="pet-health">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกสุขภาพ</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="grid justify-items-center">
          <el-image
            style="width: 100px; height: 100px"
            :src="pet?.image?.medium"
            lazy
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="lead">{{ pet?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.type?.name }}</div>
        <div class="text-gray-400">{{ pet?.breed?.name }}</div>
      </div>
      <el-divider></el-divider>
      <el-row class="mt-2">
        <el-col class="text-sm text-gray-400">วันที่ทำกิจกรรม</el-col>
        <el-col>{{ displayDateFormat(healthForm?.date) }}</el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col class="text-sm text-gray-400">ชนิดการบันทึก</el-col>
        <el-col>
          <div class="flex">
            <el-button>
              <div class="flex">
                <img
                  v-if="currentType"
                  :src="require(`@/assets/icons/${this.currentType?.image}`)"
                  :width="currentType?.width"
                />
                <span class="ml-2"> {{ currentType?.name }}</span>
              </div>
            </el-button>
          </div>
        </el-col>
      </el-row>

      <span v-if="healthForm?.type === 0">
        <el-row
          class="mt-2"
          v-for="(item, index) in healthForm?.prevention_items"
          :key="index"
        >
          <el-col class="text-sm text-gray-400"
            >{{ index + 1 }}. รายการบันทึก</el-col
          >
          <el-col>{{ item?.categoryName }}</el-col>
          <el-col class="text-sm text-gray-400">ผลิตภัณฑ์ที่ใช้</el-col>
          <el-col>{{ item?.trade_name }}</el-col>
        </el-row>
      </span>

      <span v-else-if="healthForm?.type === 2">
        <el-row
          class="mt-2"
          v-for="(item, index) in healthForm?.food_items"
          :key="index"
        >
          <el-col class="text-sm text-gray-400"
            >{{ index + 1 }}. ชนิด/ยี่ห้อ</el-col
          >
          <el-col>{{ item?.categoryName }}</el-col>
          <el-col class="text-sm text-gray-400">ผลิตภัณฑ์ที่ใช้</el-col>
          <el-col>{{ item?.trade_name }}</el-col>
        </el-row>
      </span>

      <el-row class="mt-2" v-else-if="healthForm?.type === 4">
        <el-col class="text-sm text-gray-400">น้ำหนัก (kg)</el-col>
        <el-col>{{ healthForm?.weight?.weight }}</el-col>
      </el-row>

      <span v-else>
        <el-row class="mt-2">
          <el-col class="text-sm text-gray-400">หัวเรื่อง</el-col>
          <el-col>{{ healthForm?.detail?.subject }}</el-col>
        </el-row>
        <el-row class="mt-2">
          <el-col class="text-sm text-gray-400">ป้ายกำกับ</el-col>
          <el-col>
            <el-tag
              v-for="(item, index) in healthForm?.detail?.tags"
              :key="index"
              color="#005972"
              effect="dark"
              class="mx-3"
              size="medium"
            >
              {{ item }}
            </el-tag>
          </el-col>
        </el-row>
      </span>

      <el-row class="mt-2">
        <el-col class="text-sm text-gray-400">ข้อมูลเพิ่มเติม</el-col>
        <el-col>{{ healthForm?.note || "-" }}</el-col>
      </el-row>
      <el-row class="mt-2" v-if="healthForm?.attachment">
        <el-col class="text-sm text-gray-400">เอกสารแนบ</el-col>
        <el-col>
          <div class="grid grid-cols-2 gap-2">
            <div v-for="(file, index) in healthForm?.attachment" :key="index">
              <el-link
                v-if="!/^image+/.test(file.type)"
                class="pdf-container"
                @click="openFile(file)"
                :underline="false"
                rel="norefferer"
              >
                PDF
              </el-link>
              <el-image
                v-else
                style="width: 150px; height: 150px; cursor: zoom-in"
                :src="readImage(file)"
                fit="cover"
                @click="showDialogImage(readImage(file))"
              ></el-image>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col class="text-sm text-gray-400">วันที่ครั้งถัดไป</el-col>
        <el-col v-if="healthForm?.next_date">{{
          displayDateFormat(healthForm?.next_date)
        }}</el-col>
        <el-col v-else>-</el-col>
      </el-row>
      <el-row class="mt-2">
        <el-col class="text-sm text-gray-400">วันที่แจ้งเตือน</el-col>
        <el-col v-if="healthForm?.notify_date">{{
          displayDateFormat(healthForm?.notify_date)
        }}</el-col>
        <el-col v-else>-</el-col>
      </el-row>

      <div class="type-select grid grid-cols-2 gap-2 mt-2">
        <div class="flex">
          <el-button class="grow" @click="$router.go(-1)">
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex">
          <el-button class="grow" type="primary" @click="submitForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ยืนยันการบันทึก</span>
              </div>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <ShowImage ref="dialogImage" />
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import Subnavbar from "@/components/navigation/Subnavbar";
import ShowImage from "@/components/dialog/pet/ShowImage.vue";
export default {
  components: {
    Subnavbar,
    ShowImage,
  },
  data() {
    return {
      currentType: null,
      typeList: [
        {
          id: 0,
          name: "การป้องกัน",
          image: "prevention.png",
          width: "20px",
        },
        {
          id: 1,
          name: "ป่วย/ผิดปกติ",
          image: "sick.png",
          width: "15px",
        },
        {
          id: 2,
          name: "อาหาร",
          image: "food.png",
          width: "20px",
        },
        {
          id: 3,
          name: "พฤติกรรม",
          image: "behavior.png",
          width: "20px",
        },
        {
          id: 4,
          name: "น้ำหนัก",
          image: "weight.png",
          width: "20px",
        },
        {
          id: 5,
          name: "อื่นๆ",
          image: "orther.png",
          width: "20px",
        },
      ],
    };
  },
  computed: {
    pet: get("owner/pet"),
    healthForm: get("healthRecord/healthForm"),
  },
  mounted() {
    const findType = this.typeList.find((it) => it.id === this.healthForm.type);
    if (findType) {
      this.currentType = findType;
    }
  },
  methods: {
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    readImage(file) {
      return URL.createObjectURL(file);
    },
    async submitForm() {
      switch (this.healthForm.type) {
        case 0:
          this.healthForm["prevention_items"] =
            this.healthForm.prevention_items.map((it) => ({
              category: it.category,
              product: it.product,
            }));
          break;
        case 2:
          this.healthForm["food_items"] = this.healthForm.food_items.map(
            (it) => ({
              category: it.category,
              product: it.product,
            })
          );
          break;
      }
      const formData = new FormData();

      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        const { id } = await this.$store.dispatch(
          "healthRecord/saveHealthRecord",
          {
            data: this.healthForm,
          }
        );

        if (this.healthForm.attachment.length) {
          for (const file of this.healthForm.attachment) {
            formData.append("health_record", id);
            formData.append("attachment", file);
            await this.$store.dispatch(
              "healthRecord/saveHealthRecordAttachments",
              {
                formData,
              }
            );
          }
        }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);

        this.$router.push({
          name: "petProfile",
          params: { id: this.pet.id },
          query: { tab: "diary" },
        });
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
    openFile(file) {
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    showDialogImage(src) {
      this.$refs.dialogImage.show(src);
    },
    showProfile() {
      if (this.pet?.owners?.length) {
        return this.pet?.owners[0]?.profile?.firstname;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pet-health {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}
.form-block {
  &.standalone {
    @extend %block-container;
    padding: 0px 20px 20px 20px;
  }
  .pdf-container {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background-color: #02b0e1;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
  }
}

.el-divider--horizontal {
  margin: 10px 0;
}
.el-tag {
  border: #fff;
  margin: 2px 2px;
}
</style>