<template>
  <div class="pet-diary-timeline">
    <el-timeline v-if="activities.length">
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
        :timestamp="displayDateFormat(activity.date)"
        placement="top"
      >
        <el-card>
          <div class="container p-4">
            <p class="text-lg text-[#156f88]">{{ activity?.type_name }}</p>

            <span class="mt-2" v-if="activity?.type === 0">
              <el-row
                class="mt-2"
                v-for="(item, index) in activity?.prevention_items"
                :key="index"
              >
                <el-col class="text-gray-500 text-base">
                  {{ item?.category_name }}
                </el-col>
                <el-col class=""> ผลิตภัณฑ์ : {{ item?.product_name }} </el-col>
              </el-row>
            </span>

            <span v-else-if="activity?.type === 2">
              <el-row
                class="mt-2"
                v-for="(item, index) in activity?.food_items"
                :key="index"
              >
                <el-col class="text-gray-500 text-base">
                  {{ item?.category_name }}
                </el-col>
                <el-col class=""> ผลิตภัณฑ์ : {{ item?.product_name }} </el-col>
              </el-row>
            </span>

            <el-row class="mt-2" v-else-if="activity?.type === 4">
              <el-col class="text-gray-400 text-base">
                {{ activity?.weight?.weight }} กิโลกรัม
              </el-col>
            </el-row>

            <span v-else>
              <el-row class="mt-2">
                <el-col class="text-gray-400 text-base">
                  {{ activity?.detail?.subject }}
                </el-col>
              </el-row>
              <el-row class="mt-2">
                <el-col>
                  <el-tag
                    v-for="(item, index) in activity?.detail?.tags"
                    :key="index"
                    color="#005972"
                    effect="dark"
                    size="medium"
                  >
                    {{ item }}
                  </el-tag>
                </el-col>
              </el-row>
            </span>

            <el-row class="mt-2">
              <el-col class="text-sm text-gray-400">ข้อมูลเพิ่มเติม</el-col>
              <el-col>{{ activity?.note || "-" }}</el-col>
            </el-row>
            <el-row class="mt-2">
              <el-col class="text-sm text-gray-400">เอกสารแนบ</el-col>
              <el-col
                v-for="(item, index) in activity.attachments"
                :key="index"
              >
                <el-image
                  style="width: 150px; height: 150px; cursor: zoom-in"
                  :src="item.attachment"
                  fit="cover"
                  @click="$refs.dialogImage.show(item.attachment)"
                ></el-image>
              </el-col>
            </el-row>
            <el-row class="mt-2">
              <el-col class="text-sm text-gray-400">วันที่ครั้งถัดไป</el-col>
              <el-col v-if="activity?.next_date">{{
                displayDateFormat(activity?.next_date)
              }}</el-col>
              <el-col v-else>-</el-col>
            </el-row>
            <el-row class="mt-2">
              <el-col class="text-sm text-gray-400">วันที่แจ้งเตือน</el-col>
              <el-col v-if="activity?.notify_date">{{
                displayDateFormat(activity?.notify_date)
              }}</el-col>
              <el-col v-else>-</el-col>
            </el-row>
            <el-divider class="my-0 py-0"></el-divider>
            <p class="text-gray-400">ผู้บันทึก : {{ activity?.owner_name }}</p>
          </div>
        </el-card>
      </el-timeline-item>
    </el-timeline>
    <div class="no-item" v-if="!activities.length">No timeline</div>
    <ShowImage ref="dialogImage" />
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import ShowImage from "@/components/dialog/pet/ShowImage.vue";
export default {
  components: {
    ShowImage,
  },
  data() {
    return {
      reverse: true,
      activities: [],
    };
  },
  computed: {
    pet: get("owner/pet"),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const { results } = await this.$store.dispatch(
        "healthRecord/getListHealthRecord",
        {
          query: { pet: this.pet.id },
        }
      );
      this.activities = results;

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
  },
};
</script>

<style lang="scss" >
.pet-diary-timeline {
  .el-timeline {
    padding: 0px 0px 0px 12px;
    margin: auto;
    overflow: hidden;
  }
  .el-timeline-item__timestamp {
    font-size: 16px;
    font-weight: 800;
  }

  .el-timeline-item__wrapper {
    padding-left: 50px;
  }

  .el-timeline-item__tail {
    left: 3px;
    border-left: 4px solid #e4e7ed;
  }

  .el-timeline-item {
    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-image: url("../../assets/icons/icon-timeline-vaccine.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
  .el-tag {
    border: white;
    margin: 2px 2px;
  }
}
</style>