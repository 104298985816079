<template>
  <div class="pet-owner-home">
    <Subnavbar :hasLine="true">
      <template slot="title">MY PROFILE</template>
      <template slot="actions">
        <!-- <button class="btn btn-large add-pet-btn" @click="OnNewPet">
          <img src="@/assets/icons/icon-add-pet.png" /><span class="">
            New Pet</span
          >
        </button> -->
        <el-dropdown class="btn3" @command="onFeatureClick">
          <el-button type="primary">
            <div><img src="@/assets/icons/icon-record.png" /></div>
            <div>Record<i class="el-icon-arrow-down el-icon--right"></i></div>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="petOwnerSelectPet">
              บันทึกสุขภาพ
            </el-dropdown-item>
            <el-dropdown-item command="OnNewPet">
              เพิ่มสัตว์เลี้ยง
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="add-pet-info" v-if="isHospitalConnected">
          * สัตว์เลี้ยงจะถูกผูกกับสถานพยาบาลสัตว์อัตโนมัติ
        </div>
      </template>
    </Subnavbar>
    <div class="el-row">
      <div class="el-col el-col-24 el-col-md-8">
        <div class="form-block">
          <PetOwnerDetail :owner="owner" :showProfile="isShowProfile" />
          <PetOwnerAddress v-if="isShowProfile" :addresses="owner.addresses" />
          <div class="panel-block">
            <el-button
              class="action-btn"
              type="default"
              @click="isShowProfile = !isShowProfile"
              style="width: 100%; margin-top: 10px; margin-bottom: 15px"
            >
              {{ isShowProfile ? "Hide" : "Show" }} Profile
            </el-button>
          </div>
        </div>
        <HospitalConnectionTag
          :isConnect="isHospitalConnected"
          text="เลือกสัตว์เลี้ยงเพื่อเชื่อมต่อกับ"
          v-if="isMobile()"
        />
      </div>
      <div
        class="el-col el-col-24 el-col-md-16 content-padding"
        style="height: 100%"
      >
        <div class="panel-block">
          <HospitalConnectionTag
            :isConnect="isHospitalConnected"
            text="เลือกสัตว์เลี้ยงเพื่อเชื่อมต่อกับ"
            v-if="!isMobile()"
            style="margin-bottom: 16px"
          />
          <el-tabs
            v-model="activeName"
            :stretch="isMobile()"
            :class="isHospitalConnected ? 'hide-tab' : ''"
          >
            <!-- <el-tabs v-model="activeName"> -->
            <el-tab-pane
              label="Appointment"
              name="appointment"
              style="margin-bottom: -40px"
            >
              <PetAppointmentTimeline
                viewer="owner"
                viewIn="ownerPage"
                :petHospitalId="owner.id"
                :showPetInfo="true"
                v-if="isAppointmentLoaded && owner.id"
              />
            </el-tab-pane>
            <el-tab-pane label="Pet" name="pet" style="padding-bottom: 10px">
              <div v-if="pets">
                <el-table :data="pets">
                  <el-table-column label="Pet Name" width="200">
                    <!-- <el-table-column label="Pet Name" :width="(isHospitalConnected) ? 155 : 185"> -->
                    <template slot-scope="scope">
                      <img
                        class="pet-profile"
                        v-if="scope.row.image != {}"
                        :src="scope.row.image.small"
                      />
                      <img
                        class="pet-profile"
                        v-else
                        src="@/assets/icons/icon-camera-plus.png"
                      />
                      <span class="pet-name" style="margin-left: 10px">{{
                        scope.row.name
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Type" width="80">
                    <template slot-scope="scope">
                      <img
                        class="pet-type"
                        v-if="scope.row.type.key == 'DOG'"
                        src="@/assets/icons/icon-dog.png"
                      />
                      <img
                        class="pet-type"
                        v-if="scope.row.type.key == 'CAT'"
                        src="@/assets/icons/icon-cat.png"
                      />
                      <span
                        v-if="
                          scope.row.type.key != 'DOG' &&
                          scope.row.type.key != 'CAT'
                        "
                        >{{ scope.row.type.name.split("/")[0] }}</span
                      >
                      <img
                        class="gender"
                        v-if="scope.row.gender == 1"
                        src="@/assets/icons/icon-male-white-alt.png"
                      />
                      <img
                        class="gender"
                        v-else
                        src="@/assets/icons/icon-female-white-alt.png"
                      />
                    </template>
                  </el-table-column>
                  <el-table-column v-if="!isMobile()" label="Breeds" width="90">
                    <template slot-scope="scope">
                      <template v-if="scope.row.breed">
                        {{ scope.row.breed.name }}
                      </template>
                      <template v-else>ไม่ระบุ</template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="!isMobile()"
                    label="Hospital"
                    width="120"
                  >
                    <template slot-scope="scope">
                      <span style="text-align: right !important">
                        <template
                          v-for="(hospital, index) in scope.row.hospitals"
                        >
                          {{ hospital.name }}<br :key="index" />
                        </template>
                        <template v-if="scope.row.hospitals.length == 0">
                          ยังไม่ได้เชื่อมต่อ
                        </template>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="" align="right">
                    <template slot-scope="scope">
                      <template v-if="isHospitalConnected">
                        <el-button
                          type="danger"
                          @click="openConfirmAddToHospital(scope.row)"
                          :disabled="
                            scope.row.hospitals.some(
                              (a) =>
                                a.id == parseInt(hospitalConnection.hospital.id)
                            )
                          "
                        >
                          Add
                        </el-button>
                      </template>
                      <template v-else>
                        <router-link
                          :to="{
                            name: 'petProfile',
                            params: { id: scope.row.id },
                          }"
                        >
                          <el-button
                            v-if="isMobile()"
                            type="default"
                            class="light-blue-button"
                            style="
                              width: 40px;
                              height: 40px;
                              margin-left: -10px;
                            "
                          >
                            <img
                              src="@/assets/icons/icon-arrow.png"
                              style="height: 19.4px; width: 11.5px"
                            />
                          </el-button>
                          <el-button
                            v-else
                            type="default"
                            class="light-blue-button2"
                          >
                            SEE DETAIL
                          </el-button>
                        </router-link>
                      </template>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="no-item" v-else>Please add pet</div>
            </el-tab-pane>
            <el-tab-pane
              label="Message"
              name="message"
              style="padding-bottom: 10px"
            >
              <OwnerMessageTimeline
                isMe="true"
                :ownerId="owner.id"
                v-if="isMessageLoaded && owner.id"
              />
            </el-tab-pane>
          </el-tabs>
        </div>

        <div class="panel-block" v-if="isHospitalConnected">
          <el-button
            style="width: 100%"
            type="default"
            class="action-btn"
            @click="hospitalDisconnection"
          >
            เสร็จสิ้นการเชื่อมต่อ
          </el-button>
        </div>
      </div>
    </div>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @cancel="showConfirmDialog = false"
      @confirm="confirmAddToHospital"
      v-if="hospitalConnection && pet"
    >
      <template slot="header">
        <h1 class="title">
          เชื่อมต่อ ‘{{ pet.name }}’ กับ<br />{{
            hospitalConnection.hospital.name
          }}
        </h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Subnavbar from "@/components/navigation/Subnavbar";
import HospitalConnectionTag from "@/components/pet/HospitalConnectionTag";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";
import PetOwnerDetail from "@/components/pet/PetOwnerDetail";
import PetOwnerAddress from "@/components/pet/PetOwnerAddress";
import OwnerMessageTimeline from "@/components/message/OwnerMessageTimeline";
import PetAppointmentTimeline from "@/components/appointment/PetAppointmentTimeline";

export default {
  components: {
    Subnavbar,
    HospitalConnectionTag,
    ConfirmDialog,
    PetOwnerDetail,
    PetOwnerAddress,
    PetAppointmentTimeline,
    OwnerMessageTimeline,
  },
  data() {
    return {
      owner: {
        profile: {
          addresses: null,
        },
      },
      isShowProfile: false,
      activeName: "",
      isHospitalConnected: false,
      showConfirmDialog: false,
      pet: null,
      isAppointmentLoaded: false,
      isMessageLoaded: false,
    };
  },
  computed: {
    ...mapState({
      pets: (state) => state.owner.pets,
      hospitalConnection: (state) => state.owner.hospitalConnection,
      timeline: (state) => state.owner.timeline,
    }),
    birthday() {
      if (this.owner.profile.birthdate == null) return "";
      const arr = this.owner.profile.birthdate.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฏาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ][parseInt(arr[1] - 1)];
      return `${arr[2]} ${month} ${year}`;
    },
    age() {
      if (this.owner.profile.birthdate == null) return "";
      const arr = this.owner.profile.birthdate.split("-");
      const dt = new Date();
      const year = parseInt(arr[0]) + 543;
      const age = dt.getYear() + 2443 - year;
      return age;
    },
  },
  async mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js"
    );
    document.head.appendChild(recaptchaScript);

    this.activeName = "appointment";
    const { tab } = this.$route.query;
    if (tab) this.activeName = tab;

    await this.$store.dispatch("ui/setIsFullScreenLoading", true);
    await this.$store.dispatch("owner/listPets");
    await this.$store.dispatch("owner/getOwnerProfile");
    this.owner = this.$store.state.owner.profile;
    this.isHospitalConnected = localStorage.getItem("secret_code") !== null;
    if (this.isHospitalConnected) this.activeName = "pet";
    await this.$store.dispatch("ui/setIsFullScreenLoading", false);
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    OnNewPet() {
      this.$store.commit("owner/SET_CREATE_FORM", {});
      this.$store.commit("owner/SET_PET", {});
      this.$router.push({ name: "petGeneralInfo" });
    },
    hospitalDisconnection() {
      localStorage.removeItem("secret_code");
      this.isHospitalConnected = false;

      this.$notify({
        message: `สิ้นสุดการเชื่อมต่อโรงพยาบาล`,
        type: "success",
      });
    },
    openConfirmAddToHospital(pet) {
      this.showConfirmDialog = true;
      this.pet = pet;
    },
    async confirmAddToHospital() {
      this.showConfirmDialog = false;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("owner/addPetToHospital", {
        data: {
          hospital_id: this.hospitalConnection.hospital.id,
          pets_id: [this.pet.id],
        },
      });
      await this.$store.dispatch("owner/listPets");
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    onFeatureClick(page) {
      switch (page) {
        case "petOwnerSelectPet":
          this.$router.push({ name: page });
          break;
        case "OnNewPet":
          this.OnNewPet();
          break;
      }
    },
  },
  watch: {
    activeName(newValue) {
      this.$router.replace({ query: { ...this.$route.query, tab: newValue } });
      if (newValue === "message") {
        this.isMessageLoaded = true;
      } else if (newValue === "appointment") {
        this.isAppointmentLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  max-width: 320px;
  margin: 0 auto;
  padding: 12px;
}
.form-block {
  @extend %block-container;
  @extend %form-style;

  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0 auto;

  max-width: 400px;
}
.el-col {
  padding-left: 12px;
  padding-right: 12px;
}
.panel-block {
  // @extend %block-container;
  // @extend %form-style;
  padding: 0px;
  margin-bottom: 10px;

  border: none;
  background: none;
  box-shadow: none;

  // padding-bottom: 48px;
  .el-card {
    box-shadow: none;
  }

  // @include mobile {
  //   max-width: 400px;
  // }

  @include non-desktop {
    margin: 0 auto;
    max-width: 400px;
  }
}
.footer-block {
  font-size: 14px;
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
.add-pet-info {
  font-family: Default;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.36;
  letter-spacing: -0.4px;
  text-align: center;
  color: #4a4a4a;

  font-size: 12px;
  margin-top: -7px;

  @include mobile {
    font-size: 14px;
    margin-top: -9px;
  }
}

.new-pet {
  width: 64px;
  height: 19px;
  font-family: Arial;
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #082f39;
}

.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label,
      a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
button.btn-pet-login {
  width: 213px !important;
  font-size: 17px !important;
}

img.profile {
  width: 128px;
  height: 128px;
  object-fit: contain;
  border-radius: 60px;
}

img.pet-profile {
  display: inline;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #979797;
}

img.gender {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

img.pet-type {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.el-table {
  img {
    display: inline;
    vertical-align: middle;
  }

  .el-button {
    // padding: 12px;
    // width: 60px;
    height: 40px;
    border-radius: 4px;
    // background-color: #e04e1d;

    // font-family: default;
    // font-size: 14px;
    // font-weight: 900;
    // font-style: normal;
    // font-stretch: normal;
    // line-height: normal;
    // letter-spacing: normal;
    // color: #ffffff;

    &.light-blue-button {
      width: 40px;
      height: 40px;
      padding: 0px;
      border-radius: 4px;
      &:not(:hover) {
        background-color: #c2e5f0;
        border: none;
      }
    }
  }

  .pet-name {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #1890ff;
  }
}

.title {
  font-family: default;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #030303;
}

button {
  &.add-pet-btn {
    width: 145px;
    // height: 60px;
    border-radius: 5px;
    background-color: #c8ce14;
    color: black;

    text-align: center;
    margin: 0 auto;

    vertical-align: middle;

    img,
    span {
      display: inline;
      vertical-align: middle;
    }

    img {
      width: 20px;
      height: 22px;
      object-fit: contain;
    }

    span {
      margin-left: 6px;
      font-family: Arial;
      font-size: 17px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.4px;
      text-align: center;
      color: #082f39;
    }
  }
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }

    @include mobile {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }

  &.edit-btn {
    position: relative;
    right: 0;
    margin-top: -44px;
    margin-right: -26px;
    float: right;

    z-index: 1;
  }

  &.connect-btn {
    width: 100%;

    display: block;
    clear: both;

    height: 50px;
    border-radius: 5px;

    font-family: Arial;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    margin-left: 0px;
    margin-bottom: 14px;
    // border: solid 2px #e7e7e7;
    // background-color: #0384ff;
  }
}

.el-tabs__nav-wrap::after {
  background-color: none;
}

.el-card__header {
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
}

.location-picker-container {
  // margin: -20px;
  // margin-top: 0px;
  width: 100%;
  height: 200px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      color: #8e8e93;
    }
  }
}

.card-block {
  .header {
    padding: 12px;
    border-radius: 4px 4px 0px 0px;
    border: solid 1px #e6eaee;
    background-image: linear-gradient(to top, #f4f7fa, #ffffff);

    font-family: default;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #354052;
  }
  .contain {
    padding: 0px;
    border-radius: 0px 0px 4px 4px;
    border: solid 1px #e6eaee;
    background-color: #ffffff;
    .row {
      padding: 12px;
    }
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}
</style>

<style lang="scss">
.pet-owner-home {
  .content-padding {
    padding: 16px 12px 0px 24px;

    @include mobile {
      padding: 12px 12px 0px 12px;
    }
  }

  .el-tabs__item {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8e8e93;

    &.is-active {
      color: #000000;
    }
  }

  .el-tabs__active-bar {
    height: 3px;
  }

  .el-tabs__nav-wrap::after {
    background: none;
  }

  .el-card__body {
    padding: 0px;
  }

  .el-card {
    border: none;
    box-shadow: none;
  }

  // .show-mobile {
  //   display: none;
  // }

  // @include mobile {
  //   .show-mobile {
  //     display: block
  //   }
  // }
}
</style>

<style lang="scss">
.pet-owner-home {
  @include mobile {
    .el-tabs__item {
      padding: 0px;

      font-family: default;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8e8e93;

      &.is-active {
        color: #000000;
      }
    }

    .el-tabs__active-bar {
      margin-left: -5%;
      width: 55% !important;
      height: 3px;
    }

    .el-tabs__nav-wrap::after {
      background: none;
    }

    .el-table {
      th {
        background-color: #f5f8fa;
      }
    }

    .hide-mobile {
      display: none;
    }
  }

  .hide-tab {
    .el-tabs__header {
      display: none;
    }
  }

  th {
    background: #f5f8fa;
  }

  .light-blue-button2 {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #156f88;

    border-radius: 4px;
    border: solid 1px #156db1;
  }

  .el-button--danger.is-disabled {
    background: #cfcfcf;
    border-color: #cfcfcf;
  }

  // .el-table {
  //   margin-bottom: 10px;
  // }
}
</style>
