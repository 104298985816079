<template>
  <div class="pet-health">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกสุขภาพ</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="title">ยินดีต้อนรับสู่ระบบบันทึกสุขภาพ</div>
        <div class="grid justify-items-center">
          <el-image
            style="width: 100px; height: 100px"
            :src="pet?.image?.medium"
            lazy
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="lead">{{ pet?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.type?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.breed?.name }}</div>
      </div>

      <el-form :model="form" :rules="rules" ref="FormHealth">
        <el-form-item prop="date">
          <span class="form-label"
            >วันที่ทำกิจกรรม <span class="red">*</span></span
          >
          <el-date-picker
            v-model="form.date"
            type="date"
            format="dd MMMM yyyy"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <span class="form-label"
            >เลือกชนิดการบันทึก <span class="red">*</span></span
          >
          <div class="grid grid-cols-3 gap-2">
            <div class="flex">
              <el-button
                :class="{ grow: true }"
                :plain="form.type === 0"
                :type="form.type === 0 ? 'primary' : ''"
                @click="setTypePet('PetHealthTypePreventionForm', 0)"
              >
                <div class="flex">
                  <img src="@/assets/icons/prevention.png" width="20px" />
                  <span class="md:ml-2 ml-1"> การป้องกัน</span>
                </div>
              </el-button>
            </div>
            <div class="flex">
              <el-button
                :plain="form.type === 1"
                :type="form.type === 1 ? 'primary' : ''"
                class="grow"
                @click="setTypePet('PetHealthTypeSickForm', 1)"
              >
                <div class="flex">
                  <img src="@/assets/icons/sick.png" width="20px" />
                  <span class="md:ml-2 ml-1"> ป่วย/ผิดปกติ</span>
                </div>
              </el-button>
            </div>
            <div class="flex">
              <el-button
                class="grow"
                :plain="form.type === 2"
                :type="form.type === 2 ? 'primary' : ''"
                @click="setTypePet('PetHealthTypeFoodForm', 2)"
              >
                <div class="flex">
                  <img src="@/assets/icons/food.png" width="25px" />
                  <span class="ml-2"> อาหาร</span>
                </div>
              </el-button>
            </div>
            <div class="flex">
              <el-button
                class="grow"
                :plain="form.type === 3"
                :type="form.type === 3 ? 'primary' : ''"
                @click="setTypePet('PetHealthTypeSickForm', 3)"
              >
                <div class="flex">
                  <img src="@/assets/icons/behavior.png" width="20px" />
                  <span class="ml-2"> พฤติกรรม</span>
                </div>
              </el-button>
            </div>
            <div class="flex">
              <el-button
                class="grow"
                :plain="form.type === 4"
                :type="form.type === 4 ? 'primary' : ''"
                @click="setTypePet('PetHealthTypeWeightForm', 4)"
              >
                <div class="flex">
                  <img src="@/assets/icons/weight.png" width="20px" />
                  <span class="ml-2"> น้ำหนัก</span>
                </div>
              </el-button>
            </div>
            <div class="flex">
              <el-button
                class="grow"
                :plain="form.type === 5"
                :type="form.type === 5 ? 'primary' : ''"
                @click="setTypePet('PetHealthTypeSickForm', 5)"
              >
                <div class="flex">
                  <img src="@/assets/icons/orther.png" width="20px" />
                  <span class="ml-2"> อื่นๆ</span>
                </div>
              </el-button>
            </div>
          </div>
        </el-form-item>

        <div v-show="currentType">
          <el-collapse-transition>
            <component
              v-bind:is="currentType"
              :emitData="flagEmit"
              @resultData="resultComponent"
            ></component>
          </el-collapse-transition>

          <el-form-item>
            <span class="form-label">ข้อมูลเพิ่มเติม</span>
            <el-input
              v-model="form.note"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="grid justify-items-end">
              <div>
                <div class="flex items-center">
                  <span class="mr-2">แนบภาพถ่าย / วิดีโอ / pdf</span>
                  <el-button
                    type="primary"
                    style="padding: 5px 5px"
                    @click="$refs.file.click()"
                  >
                    <img
                      src="@/assets/icons/camera.png"
                      alt="iTaam Logo"
                      width="25px"
                    />
                  </el-button>
                </div>
                <input
                  multiple
                  type="file"
                  id="file"
                  ref="file"
                  style="display: none"
                  @change="handleFileUpload"
                />
              </div>
              <div class="justify-self-start">
                <transition-group name="el-zoom-in-top">
                  <p
                    v-for="(file, index) in form.attachment"
                    :key="index"
                    style="line-height: normal"
                    class="text-gray-400"
                  >
                    <i class="el-icon-tickets"></i>
                    {{ shortenFilename(file?.name) }}
                    <el-link type="danger" @click="removeFile(index)">
                      <i class="el-icon-close red"></i
                    ></el-link>
                  </p>
                </transition-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <span class="form-label">วันที่ครั้งถัดไป</span>
            <el-date-picker
              v-model="form.next_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span class="form-label">วันที่แจ้งเตือน</span>
            <p class="text-xs text-gray-400">
              ต้องเลือกวันที่ครั้งถัดไปก่อน ถึงจะเลือกวันแจ้งเตือนได้
            </p>
            <el-date-picker
              v-model="form.notify_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-checkbox label="" v-model="form.share_with_hospital">
              ยินยอมให้สถานพยาบาลเข้าถึง เวชระเบียน (EHR)
              ประวัติที่บันทึกลงไปได้
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <div class="type-select grid grid-cols-2 gap-2">
              <div class="flex">
                <el-button class="grow" @click="$router.go(-1)">
                  <div class="flex">
                    <div>
                      <i class="el-icon-arrow-left"></i>
                    </div>
                    <div class="grow">
                      <span class="ml-2"> กลับ</span>
                    </div>
                  </div>
                </el-button>
              </div>
              <div class="flex">
                <el-button class="grow" type="primary" @click="submitForm">
                  <div class="flex">
                    <div class="grow">
                      <span class="ml-2"> บันทึก</span>
                    </div>
                    <div>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </el-button>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar";
import PetHealthTypePreventionForm from "@/components/pet/PetHealthTypePreventionForm.vue";
import PetHealthTypeFoodForm from "@/components/pet/PetHealthTypeFoodForm.vue";
import PetHealthTypeSickForm from "@/components/pet/PetHealthTypeSickForm.vue";
import PetHealthTypeWeightForm from "@/components/pet/PetHealthTypeWeightForm.vue";
import { get } from "vuex-pathify";
export default {
  components: {
    Subnavbar,
    PetHealthTypePreventionForm,
    PetHealthTypeFoodForm,
    PetHealthTypeSickForm,
    PetHealthTypeWeightForm,
  },
  data() {
    return {
      currentType: "",
      flagEmit: false,
      form: {
        pet: "",
        date: new Date().toISOString().substr(0, 10),
        type: "", // PREVENTION = 0, SICK = 1, FOOD = 2, BEHAVIOR = 3, WEIGHT = 4, OTHER = 5
        note: "",
        next_date: null,
        notify_date: null,
        share_with_hospital: false,
        attachment: [],
      },
      rules: {
        date: [
          {
            required: true,
            message: "Please pick a date",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    pet: get("owner/pet"),
  },
  methods: {
    setTypePet(currentType, type) {
      this.currentType = currentType;
      this.form.type = type;
    },
    resultComponent(item) {
      delete this.form?.prevention_items;
      delete this.form?.detail;
      delete this.form?.food_items;
      delete this.form?.weight;

      switch (this.form.type) {
        case 0:
          this.form["prevention_items"] = item;
          break;
        case 1:
        case 3:
        case 5:
          this.form["detail"] = item;
          break;
        case 2:
          this.form["food_items"] = item;
          break;
        case 4:
          this.form["weight"] = item;
          break;
      }
      this.form.pet = this.pet.id;
      this.$store.commit("healthRecord/SET_HEALTH_FORM", this.form);
      this.$router.push({ name: "petHealthPreview" });
    },
    handlePreview(file) {
      this.form.attachment.push(file);
    },
    removeFile(index) {
      this.form.attachment.splice(index, 1);
    },
    handleFileUpload(file) {
      if (!file) return false;

      this.form.attachment.push(file.target.files[0]);
    },
    shortenFilename(fileName) {
      var file_name = fileName;
      if (file_name.length > 35) {
        return (file_name =
          file_name.substr(0, 35) + "..." + file_name.substr(-7));
      } else {
        return fileName;
      }
    },
    submitForm() {
      this.$refs.FormHealth.validate((valid) => {
        if (valid) {
          this.flagEmit = !this.flagEmit;
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pet-health {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}

.form-block {
  &.standalone {
    @extend %block-container;
  }
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .el-button {
    max-height: 40px;
    padding: 12px 5px;
  }

  .type-select {
    .el-button {
      width: 48%;
    }
  }
  .el-checkbox {
    white-space: pre-wrap;
    display: flex;
  }

  .btn-remove {
    background: none;
    padding: 8px;
    border: none;
    // width: 30px;
    height: 100%;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .el-input:not(first-child) {
    margin-top: 4px;
  }
  @media only screen and (max-width: 600px) {
    img {
      width: 15px;
    }
  }
}
</style>