<template>
  <div class="navbar">
    <el-row :gutter="20">
      <!-- <el-col :span="8"> -->
      <div class="left-col">
        <template v-if="profile">
          <a
            :class="{ 'toggle-button': true, active: showSidebar }"
            @click="showSidebar = !showSidebar"
          >
            <span class="icon" />
          </a>
        </template>
      </div>
      <router-link :to="{ name: 'petOwnerHome' }">
        <img
          v-if="user_type == 'hospital_admin'"
          src="@/assets/brand/v-remind-logo-text.png"
          alt="VRemind Logo"
          class="logo"
        />
        <img
          v-else
          src="@/assets/brand/itaam-logo.png"
          alt="VRemind Logo"
          class="logo"
        />

      </router-link>
      <div class="right-col">&nbsp;</div>
      <!-- </el-col> -->
    </el-row>
    <div
      class="sidebar"
      v-show="showSidebar"
    >
      <div
        class="backdrop"
        @click="showSidebar = false"
      ></div>
      <div class="sidebar-content">
        <div v-if="profile">
          <div class="avatar">
            <template v-if="profile">
              <img
                :src="profile.logo.medium"
                v-if="profile.logo && profile.logo.medium"
              />
              <img
                :src="profile.profile.image.medium"
                v-if="profile && profile.profile && profile.profile.image && profile.profile.image.medium"
              />
            </template>
          </div>
          <div class="profile">
            <template v-if="profile && profile.profile && profile.profile.firstname">{{ profile.profile.firstname }} {{ profile.profile.lastname }}</template>
          </div>
        </div>
        <div class="header-menu">
          <a @click="navigateTo({ name: (user_type == 'hospital_admin') ? 'hospitalHome' : 'petOwnerHome' })">
            <i class="fa fa-home"></i> HOME
          </a>
        </div>
        <el-menu :default-openeds="openMenus">
            <el-menu-item
              index="logout"
              @click="$emit('logout')"
            >
              <template slot="title">
                <span>
                  <i class="fa fa-power-off"></i>Logout
                </span>
              </template>
            </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    profile: {
      type: Object,
      default: () => ({ name: "" })
    }
  },
  data() {
    return {
      messages: [],
      notificationTotal: 0,
      showSidebar: false,
      openMenus: [] // "appointment", "profile", "settings"
    };
  },
  computed: {
    ...mapState({
      currentPlan: state => state.plan.currentPlan,
    }),
    user_type: function() {
      return localStorage.getItem("user_type");
    },
    hasReportPermission() {
      return this.currentPlan.report_section.can_use_report
            || this.currentPlan.report_section.can_use_product_report
            || this.currentPlan.report_section.can_use_activity_report
            || this.currentPlan.report_section.can_use_final_diagnosis_report
            || this.currentPlan.report_section.can_use_frequency_expense_report
            || this.currentPlan.income_expense_section.can_view_pet_expense_segmentation
            || this.currentPlan.income_expense_section.can_view_owner_expense_segmentation
            || this.currentPlan.report_section.can_use_summary_report
    }
  },
  methods: {
    navigateTo(options) {
      this.$router.push(options);
      this.showSidebar = false;
    },
    gotoiTaam(url) {
      window.open(process.env.VUE_APP_ITAAM_FRONTEND_ENDPOINT + url, '_blank');
      this.showSidebar = false;
    },
    async fetchNotification() {
      this.notificationTotal = await this.$store.dispatch(
        "broadcast/getInboxCount"
      );
      const msgResponse = await this.$store.dispatch(
        "broadcast/getInboxMessages"
      );
      this.messages = msgResponse.results;
    },
  },
  mounted() {
    if (localStorage.getItem("user_type") === "hospital_admin") {
      this.fetchNotification();
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 55px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  z-index: 99;

  .logo {
    height: 40px;
    margin-top: 6px;
    margin-bottom: 14px;
  }

  .mid-col {
    text-align: center;
  }

  .left-col {
    float: left;
    padding-left: 10px;
  }

  .right-col {
    text-align: right;
    line-height: 55px;
    padding-right: 10px;

    @include mobile {
      .notification,
      .avatar {
        display: none;
      }
    }
  }

  .logo {
    position: absolute;
    left: 50%;
    margin-left: -25px;
    margin-top: 10px;
    height: 40px;
  }

  .avatar {
    cursor: pointer;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ccc;
    margin: 0 auto;

    > img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .profile {
    position: relative;
    vertical-align: middle;
    margin-right: 20px;
    line-height: 1.4em;
    color: #fff;
    text-align: center;
    padding: 10px;

    > i {
      cursor: pointer;
      font-size: 1.2em;
      line-height: 55px;
    }
  }

  .notification {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    margin-bottom: 12px;
    line-height: 20px;

    > i {
      cursor: pointer;
      margin-top: 20px;
      color: #333;
      font-size: 1.5em;
      line-height: 1em;
    }

    .total {
      position: absolute;
      left: 12px;
      top: 14px;
      width: 16px;
      height: 16px;
      border-radius: 20px;
      background-color: red;
      color: #fff;
      display: inline-block;
      text-align: center;
      font-size: 11px;
      line-height: 16px;
    }
  }

  .toggle-button {
    cursor: pointer;
    display: inline-block;
    width: 55px;
    height: 55px;
    text-align: center;

    > .icon {
      display: inline-block;
      margin-top: 18px;
      width: 20px;
      height: 20px;
      background: url("../../assets/icons/icon-toggle-button.png") no-repeat;
      background-size: contain;
    }

    &:hover,
    &.active {
      background: #156f88;

      > .icon {
        background-image: url("../../assets/icons/icon-toggle-button-active.png");
      }
    }
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

.message-container {
  margin: -10px -10px 0;
  height: 300px;
  overflow-y: scroll;

  .title {
    margin: 0;
    padding: 0 0 5px 0;
    font-size: 0.9em;
    text-align: center;
    border-bottom: 1px #ccc solid;
  }

  .message {
    cursor: pointer;
    font-size: 0.8em;
    padding: 10px 0 0 10px;
    border-bottom: 1px solid #ccc;

    &.is_seen {
      background: #f5f8fa;
    }

    .thumbnail {
      padding: 0 2px;
      img {
        width: 100%;
      }
    }

    .content {
      padding: 0 5px;
    }

    .footer {
      padding: 5px;
    }
  }
}
.sidebar .el-submenu .el-menu-item.request-hospital {
  height: 60px;
  line-height: 20px;
  padding-top: 10px;
}
</style>
