<template>
  <div id="app">
    <router-view/>
    <FullScreenLoading v-if="isFullScreenLoading"></FullScreenLoading>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FullScreenLoading from "@/components/loading/FullScreenLoading";

export default {
  components: {
    FullScreenLoading
  },
  computed: {
    ...mapState({
      isFullScreenLoading: state => state.ui.isFullScreenLoading,
      plan: state => state.plan.currentPlan,
    })
  },
  methods: {
    // isRuleCanUse(type) {
    //   switch(type) {
    //     case 'inventory': return this.plan.stock_section.can_add_stock 
    //     || this.plan.stock_section.can_view_stock;
    //     case 'product_setting': return this.plan.setting_section.can_use_product_setting
    //   }
    // },
    // isRuleCanView(type) {
    //   switch(type) {
    //     case 'inventory': return this.plan.stock_section.can_view_stock
    //   }
    // },
    // isRuleCanAdd(type) {
    //   switch(type) {
    //     case 'inventory': return this.plan.stock_section.can_add_stock
    //   }
    // },
    // isRuleCanDelete(type) {
      
    // },
    // isRuleCan(action, type) {
    //   const user_type = localStorage.getItem("user_type");
    //   if (user_type && user_type === 'hospital_admin') {
    //     switch(action) {
    //       case 'use': return isRuleCanUse(type);
    //       case 'view': return isRuleCanView(type);
    //       case 'add': return isRuleCanAdd(type);
    //       case 'delete': return isRuleCanDelete(type);
    //     }
    //   }
    // }
  },
  created() {
    this.$store.dispatch("auth/autoLogin");
    const user_type = localStorage.getItem("user_type");
    if (user_type && user_type === 'hospital_admin') {
      this.$store.dispatch("plan/getCurrentPlan");
    }
  }
}
</script>


<style lang="scss">
#app {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
