import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    profile: null,
    hospital: null,
    vets: [],
    vet: null,
    staffroles: [],
    staffs: [],
    staff: null,
    qrcode: null,
    hospitals: [],
    receiptsetting: null,
    othersetting: null,
    todayoverview: null,
  },
  getters: {
    profile: state => state.profile,
    hospital: state => state.hospital,
  },
  asyncActions: [
		{
			name: 'listHospitals',
			method: 'get',
      url: endpoints.HOSPITAL_LIST,
      setState: 'hospitals'
    },
    {
      name: "getQRCode",
      method: "post",
      url: endpoints.hospitalQRCode,
      setState: 'qrcode'
    },
    {
      name: "getProfile",
      method: "get",
      url: endpoints.hospitalProfile,
      setState: 'profile'
    },
    {
      name: "updateProfile",
      method: "patch",
      url: endpoints.hospitalProfile,
      setState: 'profile'
    },
    {
      name: "register",
      method: "post",
      url: endpoints.hospitalRegister,
      onSuccess: (result, payload, { commit }) => {
        if (result) {
          commit('SET_HOSPITAL', result);
        }
      },
      onError: (result, payload, { commit }) => {
        commit('SET_HOSPITAL', null);
      }
    },
    {
      name: "verify",
      method: "get",
      url: endpoints.hospitalVerify,
      onError: (result, payload, { commit }) => {
        commit('SET_HOSPITAL', null);
      }
    },
    {
      name: "listVets",
      method: "get",
      url: endpoints.hospitalVet,
      setState: 'vets',
      before(payload, { commit }) {
        commit('SET_VETS', [])
      }
    },
    {
      name: "listAllVets",
      method: "get",
      url: `${endpoints.hospitalVet}?all=true`,
      setState: 'vets',
      before(payload, { commit }) {
        commit('SET_VETS', [])
      }
    },
    {
      name: "getVet",
      method: "get",
      url: ({ id }) => `${endpoints.hospitalVet}${id}/`,
      setState: "vet",
      before(payload, { commit }) {
        commit('SET_VET', null)
      }
    },
    {
      name: "getAllVet",
      method: "get",
      url: ({ id }) => `${endpoints.hospitalVet}${id}/?all=true`,
      setState: 'vet',
      before(payload, { commit }) {
        commit('SET_VET', [])
      }
    },
    {
      name: "createVet",
      method: "post",
      url: endpoints.hospitalVet,
    },
    {
      name: "updateVet",
      method: "patch",
      url: ({ id }) => `${endpoints.hospitalVet}${id}/`,
    },
    {
      name: "deleteVet",
      method: "delete",
      url: ({ id }) => `${endpoints.hospitalVet}${id}/`,
    },
    {
      name: "listStaffRole",
      method: "get",
      url: endpoints.hospitalStaff + 'roles/',
      setState: 'staffroles',
      before(payload, { commit }) {
        commit('SET_STAFFROLES', [])
      }
    },
    {
      name: "listStaff",
      method: "get",
      url: endpoints.hospitalStaff,
      setState: 'staffs',
      before(payload, { commit }) {
        commit('SET_VETS', [])
      }
    },
    {
      name: "createStaff",
      method: "post",
      url: endpoints.hospitalStaff,
    },
    {
      name: "updateStaff",
      method: "patch",
      url: ({ id }) => `${endpoints.hospitalStaff}${id}/`,
    },
    {
      name: "deleteStaff",
      method: "delete",
      url: ({ id }) => `${endpoints.hospitalStaff}${id}/`,
    },
    {
      name: "getStaff",
      method: "get",
      url: ({ id }) => `${endpoints.hospitalStaff}${id}/`,
      setState: "staff",
      before(payload, { commit }) {
        commit('SET_STAFF', null)
      }
    },
    {
      name: "forgetPassword",
      method: "post",
      url: endpoints.hospitalForgetPassword
    },
    {
      name: "passwordRecoveryVerify",
      method: "get",
      url: endpoints.hospitalForgetPassword,
      onSuccess: (result) => {
        return result
      }
    },
    {
      name: "passwordRecovery",
      method: "put",
      url: endpoints.hospitalForgetPassword
    },
    {
      name: "getReceiptSetting",
      method: "get",
      url: `${endpoints.hospitalProfile}receipt/setting/`,
      setState: 'receiptsetting'
    },
    {
      name: "updateReceiptSetting",
      method: "patch",
      url: `${endpoints.hospitalProfile}receipt/setting/`,
      setState: 'receiptsetting'
    },
    {
      name: "getOtherSetting",
      method: "get",
      url: `${endpoints.hospitalProfile}other/setting/`,
      setState: 'othersetting'
    },
    {
      name: "updateOtherSetting",
      method: "patch",
      url: `${endpoints.hospitalProfile}other/setting/`,
      setState: 'othersetting'
    },
    {
			name: 'getTodayOverview',
			method: 'get',
      url: endpoints.hospitalTodayOverview,
      setState: 'todayoverview',
    },
    {
      name: "getExpenseRankCondition",
      method: "get",
      url: `${endpoints.hospitalProfile}rankcondition/`,
    },
    {
      name: "updateExpenseRankCondition",
      method: "put",
      url: `${endpoints.hospitalProfile}rankcondition/`,
    },
  ]
};

export default vuexHelper.createStoreModule(config);
