const version1 = "/api/v1";

export const login = `${version1}/auth/login/`;
export const logout = `${version1}/auth/logout/`;

export const appointments = `${version1}/appointment/`;

export const medicals = `${version1}/medicalrecord/`;

export const inventory = `${version1}/stock/`;

export const petProfiles = `${version1}/pet/profiles/`;
export const petProfilesPulbic = `${version1}/pet/publics/profiles/`;
export const petOverview = `${version1}/pet/overview/`;
export const petCauseOfDeaths = `${version1}/pet/causeofdeaths/`;
export const petBreeds = `${version1}/pet/breeds/`;
export const petTypes = `${version1}/pet/types/`;
export const petExistingSearch = `${version1}/pet/existing/search/`;
export const petSystemSearch = `${version1}/pet/system/search/`;
export const petHospital = `${version1}/pet/hospital/`;
export const petReward = `${version1}/pet/rewards/`;
export const petRewardRedeem = `${version1}/pet/rewards/redeems/`;
export const petRewardOverview = `${version1}/pet/rewards/overview/`;
export const petExpense = `${version1}/pet/expense/`;

export const owner = `${version1}/owner/`;
export const ownerProfiles = `${version1}/owner/profiles/`;
export const ownerRegister = `${version1}/owner/register/`;
export const ownerOTPVerify = `${version1}/otp/verify`;
export const ownerLogin = `${version1}/auth/owner/login/`;
export const ownerProfileMe = `${version1}/owner/me/`;
export const ownerPet = `${version1}/owner/me/pets/`;
export const ownerExistingSearch = `${version1}/owner/existing/search/`;
export const ownerSystemSearch = `${version1}/owner/system/search/`;
export const ownerHospitalConnection = `${version1}/hospital/connection?secret_code=`
export const ownerForgetPassword = `${version1}/owner/password_recovery/`;
export const ownerForgetPasswordOTP = `${version1}/otp/verify/`;
export const ownerProfileCreate = `${version1}/owner/profiles/create/`;
export const ownerMeHospital = `${version1}/owner/me/hospital/`;
export const ownerSocialLine = `${version1}/owner/me/line/`;
export const ownerSocialMessenger = `${version1}/owner/me/messenger/`;
export const ownerInvite = `${version1}/owner/invite/`;
export const ownerResendOTP = `${version1}/owner/register/resend_otp/`;
export const ownerCreatePassword = `${version1}/owner/create_password/`;
export const ownerTimelineMessage = `owner/me/timeline/message/`

export const activityGroups = `${version1}/activity/groups/`;
export const activity = `${version1}/activity/activities/`;
export const activityPrintGroup = `${version1}/activity/printgroup/`;
export const activityOneProduct = `activity/of-product/`;
export const activityOneProductFromProduct = `activity/sp-product/`;
export const activitySummary = `${version1}/activity/summary/`;
export const activityrecord = `${version1}/activityrecord/records/`;
export const activitySettingAll = `${version1}/activity/settings/all/`;
export const activitySettingAllBulk = `${version1}/activity/settings/all/bulk/`;
export const activityRequestAppointment = `${version1}/activity/request-appointment/`

export const hospitalProfile = `${version1}/hospital/me/`;
export const hospitalQRCode = `${version1}/hospital/me/qrcode/`;
export const hospitalTodayOverview = `${version1}/hospital/me/overview/today/`;
export const hospitalVet = `${version1}/hospital/vet/`;
export const hospitalStaff = `${version1}/hospital/staff/`;
export const hospitalRegister = `${version1}/hospital/register/`;
export const hospitalVerify = `${version1}/hospital/register/verify/`;
export const hospitalForgetPassword = `${version1}/hospital/password_recovery/`;
export const hospitalSearch = `${version1}/hospital/request-appointment/search/`


export const locationSubdistrict = `${version1}/locations/subdistrict/`;

export const address = `${version1}/address/addresses/`;

export const broadcast = `${version1}/broadcast`

export const message = `${version1}/message`

export const article = `${version1}/article`

export const bloodDonation = `${version1}/bloodbank/blooddonation/`;
export const bloodDonationItem = `${version1}/bloodbank/blooddonation/:id/`;
export const medicalRecord = `${version1}/bloodbank/donationmedicalrecord/`;
export const medicalRecordItem = `${version1}/bloodbank/donationmedicalrecord/:id/`;
export const labExam = `${version1}/bloodbank/labexam/`;
export const labExamItem = `/bloodbank/labexam/:id/`;
export const donorCare = `${version1}/bloodbank/donorcare/`;
export const donorCareItem = `/bloodbank/donorcare/:id/`;
export const bloodCollect = `${version1}/bloodbank/bloodcollection/`;
export const bloodCollectItem = `${version1}/bloodbank/bloodcollection/:id/`;
export const bloodCollectionHistory = `${version1}/bloodbank/bloodcollectionhistory/`;
export const bloodCollectionHistoryItem = `${version1}/bloodbank/bloodcollectionhistory/:id/`;
export const LAB_EXAM_META = `${version1}/bloodbank/labexammeta/`;

export const BLOOD_REQUEST = `${version1}/bloodbank/bloodrequest/`;
export const BLOOD_REQUEST_ITEM = `${version1}/bloodbank/bloodrequest/:id/`;
export const BLOOD_REQUEST_PROFILE = `${version1}/bloodbank/bloodrequestprofile/`;
export const BLOOD_REQUEST_PROFILE_ID = `${version1}/bloodbank/bloodrequestprofile/:id/`;
export const BLOOD_REQUEST_MEDICAL_RECORD = `${version1}/bloodbank/requestmedicalrecord/`;
export const BLOOD_REQUEST_MEDICAL_RECORD_ITEM = `${version1}/bloodbank/requestmedicalrecord/:id/`;
export const BLOOD_COLLECT = `${version1}/bloodbank/bloodcollection/`;
export const BLOOD_COLLECT_ITEM = `${version1}/bloodbank/bloodcollection/:id/`;
export const BLOOD_COLLECTION_HISTORY = `${version1}/bloodbank/bloodcollectionhistory/`;
export const BLOOD_COLLECTION_HISTORY_ITEM = `${version1}/bloodbank/bloodcollectionhistory/:id/`;
export const BLOOD_REQUEST_HOSPITAL = `${version1}/bloodbank/bloodbagrequest/`;
export const BLOOD_REQUEST_HOSPITAL_ITEM = `${version1}/bloodbank/bloodbagrequest/:id/`;
export const BLOOD_REQUEST_APPROVAL = `${version1}/bloodbank/bloodbagrequestapproval/`;
export const BLOOD_REQUEST_APPROVAL_ITEM = `${version1}/bloodbank/bloodbagrequestapproval/:id/`;
export const PRODUCT_REQUEST = `${version1}/bloodbank/productrequest/`;
export const PRODUCT_REQUEST_ITEM = `${version1}/bloodbank/productrequest/:id/`;
export const CROSSMATCH = `${version1}/bloodbank/crossmatch/`;
export const CROSSMATCH_ITEM = `${version1}/bloodbank/crossmatch/:id/`;
export const FEEDBACK = `${version1}/bloodbank/feedback/`;
export const FEEDBACK_ITEM = `${version1}/bloodbank/feedback/:id/`;
export const FEEDBACK_IMAGE = `${version1}/bloodbank/feedbackpicture/`;
export const FEEDBACK_IMAGE_ID = `${version1}/bloodbank/feedbackpicture/:id/`;
export const RECEIVE_BLOOD_BAG = `${version1}/bloodbank/receivebloodbag/`;
export const RECEIVE_BLOOD_BAG_ITEM = `${version1}/bloodbank/receivebloodbag/:id/`;
export const RESERVE_BLOOD_BAG = `${version1}/bloodbank/reservebloodbag/`;
export const RESERVE_BLOOD_BAG_ITEM = `${version1}/bloodbank/reservebloodbagitem/`;
export const RESERVE_BLOOD_BAG_ITEM_ID = `${version1}/bloodbank/reservebloodbagitem/:id/`;
export const BLOOD_SUGGEST = `${version1}/bloodbank/bloodsuggest/`;
export const BLOOD_SUGGEST_ITEM = `${version1}/bloodbank/bloodsuggest/:id/`;
export const HOSPITAL_LIST = `${version1}/hospital/hospital/`;
export const DIAGNOSIS = `${version1}/bloodbank/diagnosis/`;

export const AI_QUANTITY_PREDICTION = `https://nqh48rassj.execute-api.ap-southeast-1.amazonaws.com/deploy/model/;version2/2`;
export const AI_POSSIBILITY_CALCULATION = `https://a34cde3hmi.execute-api.ap-southeast-1.amazonaws.com/;deployment/model3`;

export const vetVerification = `${version1}/hospital/vet-verification/`;
export const plan = `${version1}/plan`

export const reportPetVisitExpenceOverview = `${version1}/report/pet/overview/visit-expence/`
export const reportPetVisitExpenceRateSummary = `${version1}/report/pet/summary/visit-expence/`
export const reportPetVisitExpenceRateAge = `${version1}/report/pet/age/visit-expence/`
export const reportPetVisitExpenceRateBreed = `${version1}/report/pet/breed/visit-expence/`
export const reportPetVisitExpenceRatePets = `${version1}/report/pet/pets/visit-expence/`
export const reportPetVisitFrequency = `${version1}/report/pet/visit/frequencyrate/`
export const reportPetExpenceRate = `${version1}/report/pet/expencerate/`
export const reportOwnerVisitExpenceRateSummary = `${version1}/report/owner/summary/visit-expence/`
export const reportOwnerVisitExpenceRateAge = `${version1}/report/owner/age/visit-expence/`
export const reportOwnerVisitExpenceRateOwners = `${version1}/report/owner/owners/visit-expence/`
export const reportOwnerVisitFrequency = `${version1}/report/owner/visit/frequencyrate/`
export const reportOwnerExpenceRate = `${version1}/report/owner/expencerate/`
export const reportFinanceAndCaseCompare = `${version1}/report/financeandcasebymonth/`
export const reportPetAndOwernCompare = `${version1}/report/petandownerbymonth/`
export const reportAppointmentCompare = `${version1}/report/appointmentbymonth/`
export const reportPetOwners = `${version1}report/pet_owners/`;
export const reportPets = `${version1}report/pets/`;
export const reportBloodbank = `${version1}report/bloodbank/`;

export const productsMinimal = `${version1}/product/products/minimal/`
export const productsMinimalRealImage = `${version1}/product/products/minimalrealimage/`
export const products = `${version1}/product/products/`
export const productType = `${version1}/product/type/`
export const productUnit = `${version1}/product/unit/`
export const productSummary = `${version1}/product/summary/`
export const productMedicationUse = `${version1}/product/medication/medicationuse/`
export const productContact = `${version1}/product/contact/`

export const receipt = `${version1}/receipt/`

export const incomeExpenseOverview = `${version1}/incomeexpense/overview/`;
export const incomeExpense = `${version1}/incomeexpense/`;

export const POS = `${version1}/pos/standalone/`
export const POS_DETAIL = `${version1}/pos/standalone/:id/`
export const POS_OVERVIEW = `${version1}/pos/standalone/overview/`

export const Shift = `${version1}/incomeexpense/hospitalcash/`

export const HEALTH_RECORD = `${version1}/health-record/`
export const INSURANCE = `${version1}/insurance/`
