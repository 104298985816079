<template>
  <div class="pet-Insurances">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกประกันสัตว์เลี้ยง</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="title">บันทึกประกันสัตว์เลี้ยง</div>
        <div class="grid justify-items-center">
          <el-image
            style="width: 100px; height: 100px"
            :src="pet?.image?.medium"
            lazy
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="lead">{{ pet?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.type?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.breed?.name }}</div>
      </div>
      <el-collapse-transition>
        <el-form
          v-show="!tabPreview"
          :model="form"
          :rules="rules"
          ref="FormInsurances"
        >
          <el-form-item prop="company">
            <span class="form-label">บริษัทประกัน </span>
            <el-select
              v-model="form.company"
              name="selectedCompany"
              placeholder="เลือกรายการ"
              style="width: 100%; margin-top: 5px"
            >
              <el-option
                v-for="(item, index) in companiesList"
                :key="index"
                :label="item?.name"
                :value="item?.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="plan">
            <span class="form-label">แผนประกัน </span>
            <el-select
              name="selectedPlan"
              placeholder="เลือกรายการ"
              style="width: 100%; margin-top: 5px"
              v-model="form.plan"
              :loading="loadingPlan"
            >
              <el-option
                v-for="(item, index) in planList"
                :key="index"
                :label="item?.name"
                :value="item?.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="insurance_number">
            <span class="form-label">เลขกรมธรรม์</span>
            <el-input v-model="form.insurance_number"> </el-input>
          </el-form-item>
          <el-form-item prop="start_date">
            <span class="form-label">วันที่เริ่ม </span>
            <el-date-picker
              v-model="form.start_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="end_date">
            <span class="form-label">วันที่สิ้นสุด </span>
            <el-date-picker
              v-model="form.end_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <div class="grid justify-items-end">
              <div>
                <div class="flex items-center">
                  <span class="mr-2">กรมธรรม์ / ใบเคลม</span>
                  <el-button
                    type="primary"
                    style="padding: 5px 5px"
                    @click="$refs.file.click()"
                  >
                    <img
                      src="@/assets/icons/camera.png"
                      alt="iTaam Logo"
                      width="25px"
                    />
                  </el-button>
                </div>
                <input
                  multiple
                  type="file"
                  id="file"
                  ref="file"
                  style="display: none"
                  @change="handleFileUpload"
                />
              </div>
              <div class="justify-self-start">
                <transition-group name="el-zoom-in-top">
                  <p
                    v-for="(file, index) in form.insurance_document"
                    :key="index"
                    style="line-height: normal"
                    class="text-gray-400"
                  >
                    <i class="el-icon-tickets"></i>
                    {{ shortenFilename(file?.name) }}
                    <el-link type="danger" @click="removeFile(index)">
                      <i class="el-icon-close red"></i
                    ></el-link>
                  </p>
                </transition-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-checkbox label="" v-model="form.share_with_veterinary">
              ยินยอมให้สัตวแพทย์เข้าถึงข้อมูลที่บันทึกลงไปได้
            </el-checkbox>
          </el-form-item>
        </el-form>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-show="tabPreview" class="transition-box mt-4">
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">บริษัทประกัน :</div>
            <div class="shrink">{{ form?.companyName }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">แผนความคุ้มครอง : :</div>
            <div class="shrink">{{ form?.planName }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">เลขกรมธรรม์ :</div>
            <div class="shrink">{{ form?.insurance_number }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">วันที่เริ่ม :</div>
            <div class="shrink">{{ displayDateFormat(form?.start_date) }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">วันที่สิ้นสุด :</div>
            <div class="shrink">{{ displayDateFormat(form?.end_date) }}</div>
          </div>
          <div class="flex space-x-4 mt-4">
            <div class="flex-none text-gray-400">กรมธรรม์ / ใบเคลม</div>
          </div>

          <div class="grid grid-cols-2 gap-2">
            <div v-for="(file, index) in form?.insurance_document" :key="index">
              <el-link
                v-if="!/^image+/.test(file.type)"
                class="pdf-container"
                @click="openFile(file)"
                :underline="false"
                rel="norefferer"
              >
                PDF
              </el-link>
              <el-image
                v-else
                style="width: 150px; height: 150px; cursor: zoom-in"
                :src="readImage(file)"
                fit="cover"
                @click="showDialogImage(readImage(file))"
              ></el-image>
            </div>
          </div>
        </div>
      </el-collapse-transition>

      <div class="type-select grid grid-cols-2 gap-2 mt-4">
        <div class="flex">
          <el-button
            class="grow"
            @click="tabPreview ? (tabPreview = false) : $router.go(-1)"
          >
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-show="!tabPreview">
          <el-button class="grow" type="primary" @click="validateForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ถัดไป</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-show="tabPreview">
          <el-button class="grow" type="primary" @click="submitForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ยืนยัน</span>
              </div>
              <!-- <div>
                <i class="el-icon-arrow-right"></i>
              </div> -->
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <ShowImage ref="dialogImage" />
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar";
import ShowImage from "@/components/dialog/pet/ShowImage.vue";
import { get } from "vuex-pathify";
export default {
  components: {
    Subnavbar,
    ShowImage,
  },
  data() {
    return {
      rules: {
        company: [
          {
            required: true,
            message: "กรุณาเลื่อกรายการ",
            trigger: "change",
          },
        ],
        plan: [
          {
            required: true,
            message: "กรุณาเลื่อกรายการ",
            trigger: "change",
          },
        ],
        insurance_number: [
          {
            required: true,
            message: "กรุณาระบุ",
            trigger: "blur",
          },
        ],
        start_date: [
          {
            required: true,
            message: "กรุณาระบวันที่",
            trigger: "change",
          },
        ],
        end_date: [
          {
            required: true,
            message: "กรุณาระบวันที่",
            trigger: "change",
          },
        ],
      },
      form: {
        company: null,
        companyName: null,
        plan: null,
        planName: null,
        insurance_number: null,
        insurance_document: [],
        insurance_claim_document: null,
        start_date: null,
        end_date: null,
        share_with_veterinary: false,
      },
      tabPreview: false,
      companiesList: [],
      planList: [],
      loadingPlan: false,
    };
  },
  computed: {
    pet: get("owner/pet"),
  },
  watch: {
    async "form.company"(id) {
      if (id) {
        this.form.plan = null;
        this.loadingPlan = true;

        const item = this.companiesList.find((it) => it.id === id);
        this.form.companyName = item.name;

        const data = await this.$store.dispatch("insurance/getListPlan", {
          query: {
            company: id,
          },
        });
        this.planList = data;

        this.loadingPlan = false;
      }
    },
    "form.plan"(id) {
      if (!id) return false;

      const item = this.planList.find((it) => it.id === id);
      this.form.planName = item.name;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.tabPreview = false;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const data = await this.$store.dispatch("insurance/getListCompanies");
      this.companiesList = data;

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    validateForm() {
      this.$refs.FormInsurances.validate((valid) => {
        if (valid) {
          this.tabPreview = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleFileUpload(files) {
      if (!files) return false;

      Object.entries(files.target.files).forEach((entry) => {
        const [, value] = entry;
        this.form.insurance_document.push(value);
      });
    },
    removeFile(index) {
      this.form.insurance_document.splice(index, 1);
    },
    async submitForm() {
      let formData = new FormData();
      formData.append("plan", this.form.plan);
      formData.append("insurance_number", this.form.insurance_number);
      if (this.form?.insurance_document) {
        this.form?.insurance_document.forEach((file) => {
          formData.append("insurance_document", file, file?.name);
        });
      }

      formData.append("start_date", this.form.start_date);
      formData.append("end_date", this.form.end_date);

      for (var pair of formData.entries()) {
        console.log(pair[0] + " - " + pair[1]);
      }

      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      await this.$store.dispatch("pet/saveInsurance", {
        data: formData,
        params: { id: this.pet.id },
      });
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      this.$router.push({
        name: "petProfile",
        params: { id: this.pet.id },
        query: { tab: "insurance" },
      });
    },
    showProfile() {
      if (this.pet?.owners?.length) {
        return this.pet?.owners[0]?.profile?.firstname;
      } else {
        return "-";
      }
    },
    openFile(file) {
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    showDialogImage(src) {
      this.$refs.dialogImage.show(src);
    },
    readImage(file) {
      return URL.createObjectURL(file);
    },
    shortenFilename(fileName) {
      var file_name = fileName;
      if (file_name.length > 35) {
        return (file_name =
          file_name.substr(0, 35) + "..." + file_name.substr(-7));
      } else {
        return fileName;
      }
    },
    uploadInsuranceDocument(file) {
      this.form.insurance_document = file;
    },
    uploadInsuranceClaimDocument(file) {
      this.form.insurance_claim_document = file;
    },
  },
};
</script>


<style lang="scss" scoped>
.pet-Insurances {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}

.form-block {
  &.standalone {
    @extend %block-container;
  }
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .el-button {
    max-height: 40px;
    padding: 12px 5px;
  }

  .type-select {
    .el-button {
      width: 48%;
    }
  }
  .el-checkbox {
    white-space: pre-wrap;
    display: flex;
  }

  .btn-remove {
    background: none;
    padding: 8px;
    border: none;
    // width: 30px;
    height: 100%;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .el-input:not(first-child) {
    margin-top: 4px;
  }
  .pdf-container {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background-color: #02b0e1;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
  }
}
</style>