<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <h1 class="text-center">ยินดีต้อนรับสู่ระบบนัดหมาย</h1>
      <div v-if="hospital && hospital.logo">
        <div>
          <img :src="hospital.logo.small" /> 
        </div>
        <div>
          {{ hospital.name }}
        </div>
      </div>
      <div class="mt-4">
        <label>วันและเวลานัดหมาย <span class="text-red-700">*</span></label>
        
      </div>
      <div class="h-80 overflow-y-scroll">
        <ul>
          <li v-for="pet in pets" :key="pet.id">
            <div class="inline-block w-1/4" v-if="pet.image">
              <img :src="pet.image.small" /> 
            </div>
            <div class="inline-block w-2/4">
              {{ pet.name }}
            </div>
            <div class="inline-block w-1/4">
              <el-button @click="select(pet)">เลือก</el-button>
            </div>
          </li>
        </ul>
      </div>
      <div>
          <el-button 
            type="primary" size="large" class="next-btn" 
            @click="next()">เลือกกิจกรรมนัด &gt;</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState} from "vuex";
// import ContentBox from '@/components/container/ContentBox.vue';

export default {
  components: {
    // ContentBox
  },
  data() {
    return {
      searchValue: '',
      selectedPet: null
    }
  },
  computed: {
    ...mapState({
      hospital: state => state.requestAppointment.hospital,
      pets: state => state.owner.pets,
    }),
  },
  methods: {
    select(pet) {
      this.selectedPet = pet;
    },
    next() {
      this.$router.push({ name: 'request-appointment-activities',  params: { hospital_id: this.$route.params.id, pet_id: this.selectedPet.id } })
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch("owner/listPets");
      await this.$store.dispatch("requestAppointment/getHospital", { params: { id: this.$route.params.id }});

    } catch (e) {
      await this.$store.dispatch("ui/showFetchErrorMsg");
    }
  }
}
</script>