<template>
  <div class="form-block">
    <div class="header-block">
      <div class="title">
        Create Pet Owner Profile
      </div>
      <div class="lead">
        Address<br/>
        (3/4)
      </div>
    </div>
    <el-form ref="form" :model="form">

      <el-form-item>
        <span class="form-label">7. ที่อยู่</span>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10}"
          name="address"
          placeholder="บ้านเลขที่, อาคาร, ถนน"
          v-model="form.address1"></el-input>
        <span class="error">{{ errors.first('address') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">แขวง/ตำบล (พิมพ์เพื่อค้นหา อย่างน้อย 3 ตัวอักษร)</span>
        <el-input
          type="text"
          name="subDistrict"
          v-model="subDistrict"></el-input>
        <div class="dropdown-select" v-if="subDistrictArray.length">
          <div v-for="r in subDistrictArray"
            :key="r.subdistrict.id"
            class="sub-district-select"
            @click="subdistrictClick(r)">
            {{ r.subdistrict.name_th }}, {{ r.district.name_th }}, {{ r.province.name_th }}
          </div>
        </div>
        <span class="error">{{ errors.first('subDistrict') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">เขต/อำเภอ</span>
        <el-input
          type="text"
          name="district"
          :disabled="true"
          v-model="form.district"></el-input>
        <span class="error">{{ errors.first('district') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">จังหวัด</span>
        <el-input
          type="text"
          name="province"
          :disabled="true"
          v-model="form.province"></el-input>
        <span class="error">{{ errors.first('province') }}</span>
      </el-form-item>

      <el-form-item>
        <span class="form-label">รหัสไปรษณีย์</span>
        <el-input
          type="tel"
          name="zipCode"
          v-model="form.zipcode"></el-input>
        <span class="error">{{ errors.first('zipcode') }}</span>
      </el-form-item>

      <el-form-item class="text-center">
        <router-link
          :to="{ name: 'petOwnerMapLocation' }"
          class="btn btn-plain form-submit-btn">
          &lt; Back
        </router-link>
        <el-button
          :loading="submitButtonLoading"
          type="button"
          class="btn btn-primary form-submit-btn"
          @click="onSubmit">Next ></el-button>
        <span v-for="(error, key) of errMessage"
          v-bind:key="key"
          class="error">{{ key }}: {{ error }}</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstname: '',
      subDistrictID: 0,
      submitButtonLoading: false,
      form: {},
      subDistrict: '',
      errMessage: [],
      subDistrictArray: [],
      noSearch: false
    }
  },
  watch: {
    async subDistrict(val) {
      if (!val) {
        return
      }
      if (val.length < 2 || this.noSearch) {
        this.noSearch = false
        return
      }
      var result = await this.$store.dispatch('location/getSubdistrict', {
        query: {
          'name_th__contains': val
        }
      })
      this.subDistrictArray = result
    }
  },
  async mounted() {
    await this.$store.dispatch('ui/setIsFullScreenLoading', true)
    await this.$store.dispatch('owner/getOwnerProfile')
    this.firstname = this.$store.state.owner.profile.profile.firstname
    var address = this.$store.state.owner.profile.profile.addresses[0]
    if (address && address.subdistrict) {
      this.form = {
        address1: address.address1,
        sub_district: address.subdistrict.name_th,
        district: address.district.name_th,
        province: address.province.name_th,
        zipcode: address.zipcode
      }
      this.subDistrict = address.subdistrict.name_th
      this.subDistrictID = address.subdistrict.id
    } else {
      this.form = {
        address1: '',
        sub_district: '',
        district: '',
        province: '',
        zipcode: '',
      }
      this.subDistrict = ''
    }
    this.noSearch = true
    await this.$store.dispatch('ui/setIsFullScreenLoading', false)
  },
  methods: {
    closeDropdown() {
      this.subDistrictArray = []
    },
    subdistrictClick(r) {
      this.form.sub_district = r.subdistrict.name_th
      this.form.district = r.district.name_th
      this.form.province = r.province.name_th
      if (r.subdistrict.zip_code.length == 5) {
        this.form.zipcode = r.subdistrict.zip_code
      } else {
        this.form.zipcode = ''
      }
      this.subDistrict = r.subdistrict.name_th
      this.subDistrictID = r.subdistrict.id
      this.noSearch = true
      this.closeDropdown()
    },
    async onSubmit() {
      this.submitButtonLoading = true
      try {
        await this.$store.dispatch('owner/updateProfile', {
          data: {
            profile: {
              firstname: this.firstname,
              addresses: [
                {
                  address1: this.form.address1,
                  zipcode: this.form.zipcode,
                  subdistrict: this.subDistrictID
                }
              ]
            }
          }
        })
        this.$router.push({ name: 'petOwnerRegisterSocial' })
        this.$message('Address saved')
      } catch (err) {
        this.errMessage = err
      }
      this.submitButtonLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form-block {
  @extend %block-container;
  @extend %form-style;
  .form-submit-btn {
    &.btn-primary {
      width: 142px;
    }
  }
  .dropdown-select {
    position: absolute;
    background-color: white;
    z-index: 1;
    padding: 4px 12px;
    border: 1px solid $gray;
    border-radius: 4px;
    .sub-district-select {
      cursor: pointer;
    }
  }
}
</style>
