<template>
  <div class="pet-owner-detail" v-if="owner">
    <div class="form-block">
      <router-link :to="{ name: routeEditOwner, params: { id: owner.id } }">
        <el-button
          class="edit-btn"
          type="default"
          icon="el-icon-edit"
          circle
        ></el-button>
      </router-link>
      <div class="grid justify-items-center">
        <img
          v-if="owner.profile.rank && owner.profile.rank <= 3"
          class="medal"
          :src="getStatusIcon(owner.profile.rank)"
          alt="Status icon"
        />
        <img
          class="profile"
          v-if="owner.profile.image"
          :src="owner.profile.image.large"
        />
        <img class="profile" v-else src="@/assets/icons/icon-camera-plus.png" />
        <div class="title">
          {{ `${owner.profile.firstname} ${owner.profile.lastname}` }}
        </div>
      </div>

      <template v-if="isHospital">
        <template v-if="!owner.is_mobile_phone_verify">
          <span class="resend-link"
            >* ยังไม่ได้รับการเชื่อมต่อกับ Social Account</span
          >
          <el-button
            class="btn-resend-link"
            @click="showConfirmResendLink = true"
            type="danger"
            >Resend Link to Owner</el-button
          >
        </template>
      </template>
      <!-- <template v-else> -->
      <SocialButton :profile="owner" :isHospital="isHospital" />
      <!-- </template> -->
      <template v-if="showProfile">
        <div class="detail" style="margin-top: 8px">
          <div class="key">Owner ID</div>
          <div class="value line">{{ owner.id }}</div>
        </div>
        <div class="detail">
          <div class="key">เพศ / Gender</div>
          <div class="value">
            {{ owner.profile.gender == 1 ? "ชาย" : "" }}
            {{ owner.profile.gender == 0 ? "หญิง" : "" }}
            {{ owner.profile.gender == -1 ? "ไม่ระบุ" : "" }}
          </div>
        </div>
        <div class="detail">
          <div class="key">วันเกิด / Birthday</div>
          <div class="value">
            {{ birthday }} <span class="sub">( อายุ {{ age }} ปี )</span>
          </div>
        </div>
        <div class="detail">
          <div class="key">เบอร์ติดต่อ / Tel</div>
          <div class="value">
            {{ owner.phone }}
            <span
              v-if="owner.profile.reminder_phone_field === 1"
              class="required-mark"
              >*</span
            >
          </div>
          <div
            v-if="owner.profile.reminder_phone_field === 1"
            class="required-mark for-reminder"
          >
            * for Reminder SMS
          </div>
          <div class="value">
            {{ owner.profile.phone2 }}
            <span
              v-if="owner.profile.reminder_phone_field === 2"
              class="required-mark"
              >*</span
            >
          </div>
          <div
            v-if="owner.profile.reminder_phone_field === 2"
            class="required-mark for-reminder"
          >
            * for Reminder SMS
          </div>
          <div class="value">
            {{ owner.profile.phone3 }}
            <span
              v-if="owner.profile.reminder_phone_field === 3"
              class="required-mark"
              >*</span
            >
          </div>
          <div
            v-if="owner.profile.reminder_phone_field === 3"
            class="required-mark for-reminder"
          >
            * for Reminder SMS
          </div>
        </div>
        <div class="detail">
          <div class="key">หมายเลขบัตรประชาชน</div>
          <div class="value">{{ owner.profile.id_card_number }}</div>
        </div>
        <div class="detail">
          <div class="key">อีเมลล์ / Email</div>
          <div class="value">{{ owner.profile.email }}</div>
        </div>
      </template>
    </div>

    <ConfirmDialog
      :visible="showConfirmResendLink"
      @cancel="showConfirmResendLink = false"
      @confirm="resendLink"
    >
      <template slot="header">
        <h1 class="title">ยืนยันส่ง Link สร้าง Account ให้เจ้าของสัตว์</h1>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import SocialButton from "@/components/common/SocialButton";
import ConfirmDialog from "@/components/dialog/ConfirmDialog";

export default {
  components: {
    SocialButton,
    ConfirmDialog,
  },
  props: {
    isHospital: {
      default: false,
      type: Boolean,
    },
    owner: {
      type: Object,
    },
    showProfile: {
      default: false,
      type: Boolean,
    },
    routeEditOwner: {
      default: "petOwnerGeneralInfo",
      type: String,
    },
  },
  data() {
    return {
      showConfirmResendLink: false,
    };
  },
  computed: {
    birthday() {
      if (this.owner.profile.birthdate == null) return "";
      const arr = this.owner.profile.birthdate.split("-");
      const year = parseInt(arr[0]) + 543;
      const month = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฏาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ][parseInt(arr[1] - 1)];
      return `${arr[2]} ${month} ${year}`;
    },
    age() {
      if (this.owner.profile.birthdate == null) return "";
      const arr = this.owner.profile.birthdate.split("-");
      const dt = new Date();
      const year = parseInt(arr[0]) + 543;
      const age = dt.getYear() + 2443 - year;
      return age;
    },
  },
  methods: {
    async resendLink() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var formData = new FormData();
        formData.append("phone", this.owner.phone);
        const result = await this.$store.dispatch("owner/invite", {
          formData: formData,
        });
        if (result.status != "error") {
          this.showConfirmResendLink = false;
          this.$notify({
            message: "ส่ง Link ให้เจ้าของสัตว์เรียบร้อยแล้ว",
            type: "success",
          });
        } else {
          this.$notify({
            message: result.message.detail,
            type: "error",
          });
        }
      } catch (error) {
        this.$notify({
          message: error,
          type: "error",
        });
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
      this.showConfirmResendLink = false;
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },

    getStatusIcon(value) {
      switch (value) {
        case 1:
          return require("@/assets/icons/medal-gold.svg");

        case 2:
          return require("@/assets/icons/medal-silver.svg");

        case 3:
          return require("@/assets/icons/medal-bronze.svg");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pet-owner-detail {
  .resend-link {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d0025b;
  }

  .btn-resend-link {
    background-color: #d0025b;
    margin-bottom: 20px;
  }
}
</style>

<style lang="scss" scoped>
.contain {
  max-width: 320px;
  margin: 0 auto;
  padding: 12px;
}
.form-block {
  @extend %block-container;
  @extend %form-style;

  padding-top: 9px;
  padding-bottom: 20px;
  text-align: center;
}
.panel-block {
  @extend %block-container;
  padding: 0px;
  margin-bottom: 10px;

  border: none;
  background: none;
  box-shadow: none;

  .el-card {
    box-shadow: none;
  }
}
.footer-block {
  font-size: 14px;
  text-align: center;
  span {
    color: $gray;
  }
  a {
    text-decoration: none;
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
.login-optional {
  .el-form-item__content {
    div {
      width: auto;
      display: inline-block;
      vertical-align: middle;

      .el-checkbox__label,
      a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        text-align: center;
        color: #8e8e93;
        text-decoration: none;
      }
    }
    div:first-child {
      float: left;
    }
    div:last-child {
      float: right;
    }
  }
  .el-checkbox__inner {
    border-radius: 8px;
  }
}
button.btn-pet-login {
  width: 213px !important;
  font-size: 17px !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e6eaee;
  margin-bottom: 6px;
}

.split {
  // height: 100%;
  // float: left;
  vertical-align: top;
  display: inline-block;
  // position: fixed;
  // z-index: 1;
  top: 0;
  // overflow-x: hidden;
  // padding-top: 20px;

  &.left {
    left: 0;
    width: 120px;
  }

  &.right {
    right: 0;
  }
}

img.cover {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: -70px;
  margin-top: -14px;
}

img.profile {
  left: 8px;
  margin-top: 8px;
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 60px;

  position: relative;
  z-index: 1;

  &.large {
    width: 83px;
    height: 83px;
  }
}

img.medal {
  position: relative;
  height: 2.4em;
  float: left;
  margin-right: -34px;
}

img.pet-profile {
  display: inline;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #979797;
}

img.gender {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

img.pet-type {
  width: 27px;
  height: 27px;
  object-fit: contain;
}

.title {
  font-family: default;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #030303;
}

.subtitle {
  margin-top: 0px;
  margin-bottom: 40px;

  font-family: default;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #7f8fa4;
  .c1 {
    font-weight: normal;
  }
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;
    &:not(:hover) {
      background-color: #c2e5f0;
    }
  }

  &.edit-btn {
    position: relative;
    right: 0;
    margin-top: -24px;
    margin-right: -24px;
    width: 35px;
    height: 35px;
    float: right;

    z-index: 1;
  }

  &.connect-btn {
    width: 100%;
    display: block;
    clear: both;

    height: 50px;
    border-radius: 5px;

    font-family: Arial;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;

    margin-left: 0px;
    margin-bottom: 14px;
    // border: solid 2px #e7e7e7;
    // background-color: #0384ff;
  }
}

.el-card__header {
  background-image: linear-gradient(to top, #f4f7fa, #ffffff);
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }
  .value {
    font-family: default;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }

  .for-reminder {
    font-size: 0.7em;
    line-height: 1em;
  }
}
</style>